/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { colors, FormItem, Select, showToast, Space } from "tap2visit-ui-kit";

import { changePriorityAppealApi, changeStatusAppealApi } from "api/api.gateway";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsCloseStore from "pages/appeals/store/AppealsClose.store";
import DrawerStore from "pages/appeals/store/Drawer.store";

import { ReactComponent as CriticalSvg } from "../../img/CRITICAL.svg";
import { ReactComponent as LowSvg } from "../../img/LOW.svg";
import { ReactComponent as MediumSvg } from "../../img/MEDIUM.svg";

const AppealStatus = () => {
	const [isOpenedStatusSelect, setIsOpenedStatusSelect] = useState(false);
	const [isOpenedPrioritySelect, setIsOpenedPrioritySelect] = useState(false);
	const [itemsStatusArray, setItemsStatusArray] = useState([]);

	useEffect(() => {
		const array = [
			{ text: "Ожидает обработки", key: "NEW", focused: DrawerStore.selectedAppeals.status === "NEW", disabled: true },
			{
				text: "Рассмотрение",
				key: "IN_REVIEW",
				focused: DrawerStore.selectedAppeals.status === "IN_REVIEW",
				disabled:
					DrawerStore.selectedAppeals.status === "IN_REVIEW" ||
					DrawerStore.selectedAppeals.status === "IN_PROGRESS" ||
					DrawerStore.selectedAppeals.status === "COMPLETED",
			},
			{
				text: "Решается",
				key: "IN_PROGRESS",
				focused: DrawerStore.selectedAppeals.status === "IN_PROGRESS",
				disabled: DrawerStore.selectedAppeals.status === "IN_PROGRESS",
			},
			{ text: "Завершено", key: "COMPLETED", focused: DrawerStore.selectedAppeals.status === "COMPLETED" },
		];
		setItemsStatusArray(
			DrawerStore.selectedAppeals.status === "ARCHIVED" ? [...array, { text: "Архив", key: "ARCHIVED", focused: true }] : array,
		);
	}, [DrawerStore.selectedAppeals.status]);

	const itemsPrioritySelect = [
		{ text: "Критический", key: "CRITICAL", focused: DrawerStore.selectedAppeals.priority === "CRITICAL", icon: CriticalSvg },
		{ text: "Средний", key: "MEDIUM", focused: DrawerStore.selectedAppeals.priority === "MEDIUM", icon: MediumSvg },
		{ text: "Низкий", key: "LOW", focused: DrawerStore.selectedAppeals.priority === "LOW", icon: LowSvg },
	];

	const currentStatus = itemsStatusArray.find((v) => v.focused)?.key;

	const getBackgroundStatusSelect = () => {
		switch (currentStatus) {
			case "NEW":
				return colors.surfaceTetriaryLightBlue1;
			case "IN_REVIEW":
				return colors.surfaceTetriaryYellow1;
			case "IN_PROGRESS":
				return colors.strokeWarningTransparent;
			case "COMPLETED":
				return colors.surfaceTetriaryLime1;
			default:
				return "";
		}
	};
	const getColorStatusSelect = () => {
		switch (currentStatus) {
			case "NEW":
				return colors.textTertiaryLightBlue;
			case "IN_REVIEW":
				return colors.textTertiaryYellow;
			case "IN_PROGRESS":
				return colors.textWarningDefault;
			case "COMPLETED":
				return colors.textTertiaryLime;
			default:
				return "";
		}
	};

	const changeStatusAppeal = useMutation(changeStatusAppealApi);
	const changePriorityAppeal = useMutation(changePriorityAppealApi);

	const isDisabledSelect =
		DrawerStore.selectedAppeals.status === "COMPLETED" ||
		DrawerStore.selectedAppeals.status === "ARCHIVED" ||
		!!DrawerStore.selectedAppeals.parentAppealId;

	const showDangerToast = () => {
		showToast({
			description: "Обращение связано. Для редактирования необходимо изменить главное обращение",
			type: "danger",
		});
	};
	return (
		<Space width="100%">
			<FormItem label="Статус">
				<Select
					selectedItemsStyleContainer={{ width: "100%" }}
					style={{
						background:
							DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED"
								? "#12121214"
								: getBackgroundStatusSelect(),
						color:
							DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED"
								? "black"
								: getColorStatusSelect(),
					}}
					isDisabled={isDisabledSelect}
					items={itemsStatusArray}
					isOpened={isOpenedStatusSelect}
					onClose={() => setIsOpenedStatusSelect(false)}
					onClickSelectItem={(item) => {
						if (item.key === "COMPLETED") {
							return AppealsCloseStore.setIsOpenDrawer(true);
						}

						changeStatusAppeal
							.mutateAsync({
								args: {
									status: item.key,
								},
								id: DrawerStore.selectedAppeals.id,
							})
							.then((res) => {
								DrawerStore.setSelectedAppeals(res);
								setIsOpenedStatusSelect(false);
								queryClient.invalidateQueries([QueriesKeys.appealsList]);
							});
					}}
					onClickSelect={() => {
						if (DrawerStore.selectedAppeals.parentAppealId) {
							return showDangerToast();
						}
						if (isDisabledSelect) {
							return;
						}

						setIsOpenedStatusSelect(true);
					}}
				/>
			</FormItem>
			<FormItem label="Приоритет">
				<Select
					selectedItemsStyleContainer={{ width: "100%" }}
					style={{ background: isDisabledSelect ? "#12121214" : "white" }}
					items={itemsPrioritySelect}
					isDisabled={isDisabledSelect}
					isOpened={isOpenedPrioritySelect}
					onClose={() => setIsOpenedPrioritySelect(false)}
					onClickSelect={() => {
						if (DrawerStore.selectedAppeals.parentAppealId) {
							return showDangerToast();
						}
						if (isDisabledSelect) {
							return;
						}

						setIsOpenedPrioritySelect(true);
					}}
					onClickSelectItem={(item) => {
						changePriorityAppeal
							.mutateAsync({
								args: {
									priority: item.key,
								},
								id: DrawerStore.selectedAppeals.id,
							})
							.then((res) => {
								DrawerStore.setSelectedAppeals(res);
								setIsOpenedPrioritySelect(false);
								queryClient.invalidateQueries([QueriesKeys.appealsList]);
							});
					}}
				/>
			</FormItem>
		</Space>
	);
};

export default observer(AppealStatus);
