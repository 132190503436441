import React, { FC, useState } from "react";

import Input from "components/ui/Input/Input";
import { FormItem, Modal, showToast } from "tap2visit-ui-kit";

import AnnouncementModeStore from "../store/Announcement.mode.store";

interface IModalCreateTemplate {
	createAnnouncements: () => void;
	checkClickSaveButton: boolean;
	createTemplate: (v: string) => void;
}

const ModalCreateTemplate: FC<IModalCreateTemplate> = ({ createAnnouncements, checkClickSaveButton, createTemplate }) => {
	const [name, setName] = useState("");
	return (
		<Modal
			onOk={() => {
				if (checkClickSaveButton) {
					createAnnouncements();
				}
				if (!name) {
					showToast({
						description: "Название шаблона не должно быть пустым или быть более 255 символов",
						type: "danger",
					});
				} else {
					createTemplate(name);
				}
			}}
			okText="Сохранить"
			cancelText="Отменить"
			title="Придумайте название шаблона"
			onExit={() => AnnouncementModeStore.setIsOpenCreateTemplateDrawer(false)}
			onCancel={() => AnnouncementModeStore.setIsOpenCreateTemplateDrawer(false)}>
			<FormItem label="Название шаблона">
				<Input style={{ width: "100%" }} sizeInput="medium" value={name} onChange={(e) => setName(e.target.value)} />
			</FormItem>
		</Modal>
	);
};

export default ModalCreateTemplate;
