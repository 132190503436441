import styled from "styled-components";
import { colors, Text } from "tap2visit-ui-kit";

export const TimeWrapper = styled.div`
	border: 0.5px dashed rgba(18, 18, 18, 0.16);
	padding: 6px 12px;
	background: rgba(18, 18, 18, 0.02);
	border-radius: 10px;
`;
export const ChatDropDownWrapper = styled.div`
	padding: 9px 12px;
	border: 1px solid rgba(18, 18, 18, 0.16);
	cursor: pointer;
`;

export const Overlay = styled.div`
	position: fixed;
	padding-top: 50px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
`;
export const ChatDropDownBlockWrapper = styled.div`
	position: absolute;
	background: white;
	max-height: 330px;
	overflow: auto;
	width: 100%;
	left: 0;
	top: 60px;
`;
export const TextStyledMessage = styled(Text)`
	max-width: 500px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const LoaderWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 65%;
	right: 90%;
	top: 50%;
	text-align: center;
`;

export const SearchWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 15px;
	z-index: 1000;
	position: relative;
	border-bottom: 1px solid ${colors.strokeDivider};
`;
export const UploadWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const RepliedMessageBox = styled.div`
	border-left: 3px solid #99752c;
	background: #12121214;
	padding: 10px;
	border-radius: 2px;
`;
export const ReplyWrapperBox = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const ReplyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

export const TextStyled = styled(Text)`
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 0px;
`;
export const ChatInformationFotterButton = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
`;
export const ChatInformationFotter = styled.div`
	padding: 16px;
`;
export const ChatInformationWrapperDate = styled(Text)`
	border: 0.5px solid ${colors.strokeDivider};
	background: rgba(18, 18, 18, 0.04);
	border-radius: 5px;
	padding: 0px 8px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	width: 100px;
`;
export const ChatInformationWrapperTimeWrapper = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: flex-end;
	position: relative;
`;
export const ChatInformationWrapperTime = styled(Text)`
	display: flex;
	justify-content: flex-end;
`;
export const ChatInformationWrapperLeftBox = styled.div`
	background: rgba(18, 18, 18, 0.04);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 16px;
	min-width: 200px;
`;
export const ChatInformationWrapperRightBox = styled.div`
	background: rgba(190, 152, 65, 1);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 16px;
	min-width: 200px;
`;
export const ChatInformationWrapperLeft = styled.div`
	display: flex;
	gap: 10px;
	margin: 10px 0;
`;
export const ChatInformationWrapperRight = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 20px;
`;
export const ChatInformationContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-left: 320px;
`;
export const ChatInformationWrapper = styled.div`
	background: rgba(18, 18, 18, 0.02);
	height: 70vh;
	padding: 16px;
	position: relative;
	gap: 10px;
	overflow: scroll;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
`;
export const ChatInformationHeaderButton = styled.div`
	display: flex;
	gap: 10px;
`;

export const ChatInformationHeaderTextWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const ChatInformationHeader = styled.div`
	padding: 18px;
	border-bottom: 1px solid ${colors.strokeDivider};
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
