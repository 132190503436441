import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import { ReactComponent as ExcludeSvg } from "public/exclude.svg";
import { ROUTES } from "routes/constants/routes.constants";

import PollsItemStore from "../store/PollsItem.store";

import PollsTabs from "./components/Polls.tabs";
import PollsTable from "./components/tables/Polls.table";
import { PollsMainPaddingContent } from "./Polls.styled";
import PollsConfirmStore from "./store/Polls.confirm.store";
import PollsTabsStore from "./store/Polls.tabs.store";

const TableFilterInstanceStore = new BuildingFilterStore();

const Polls = () => {
	const navigate = useNavigate();

	const handleCreateTemplate = () => {
		PollsItemStore.toggleIsTemplate(true);
		navigate(`/${ROUTES.VOTE}/${ROUTES.CREATE_TEMPLATE}`);
	};

	return (
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<PollsMainPaddingContent>
				<PollsTabs />
			</PollsMainPaddingContent>

			<PollsMainPaddingContent>
				{PollsTabsStore.getActiveTab() === "template" ? (
					<Button onClick={handleCreateTemplate} size="medium" typeButton="primary" icon={ExcludeSvg}>
						Создать шаблон
					</Button>
				) : (
					<TableFilter
						customSwitchOptions={{
							callback: PollsTabsStore.toggleOnlyActiveStatus,
							label: "Статус",
							isActive: PollsTabsStore.onlyActiveStatus,
							switchLabel: "Только активные",
							isVisibleSwitch: PollsTabsStore.activeTab === "active",
						}}
					/>
				)}
			</PollsMainPaddingContent>

			<Spacer px={16} />
			<PollsTable />

			<BaseModal
				title={
					PollsConfirmStore.isVisibleConfirmArchiveModal
						? `Уверены, что хотите остановить ${PollsConfirmStore.actionPollType === "POLL" ? "опрос" : "голосование"}?`
						: "Уверены, что хотите удалить?"
				}
				description={PollsConfirmStore.actionPollName}
				okText={"Да"}
				contentContainerStyle={{ wordWrap: "break-word" }}
				cancelText="Нет"
				isVisible={PollsConfirmStore.isVisibleConfirmArchiveModal || PollsConfirmStore.isVisibleConfirmDeleteModal}
				status="delete"
				onCancel={PollsConfirmStore.cancel}
				onOk={PollsConfirmStore.confirm}
				onExit={PollsConfirmStore.cancel}
			/>
		</TableFilterContext.Provider>
	);
};

export default observer(Polls);
