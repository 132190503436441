import { useEffect } from "react";

import { Space } from "antd";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { colors, Drawer } from "tap2visit-ui-kit";

import { getBuildingObjectsApi } from "api/api.realEstate";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import { ManagerStore } from "components/Citizens/store/ModalManagerStore";
import type { IBuildingObject } from "interfaces/IBuilding";
import { QueriesKeys } from "interfaces/queriesKeys";
import { getBuildingObjectType } from "utils/getBuildingObjectType";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

import CitizenCommentsCollapse from "./CitizenComments.collapse";
import CitizenHeaderInformation from "./CitizenHeaderInformation";
import CitizenOwnersInformation from "./CitizenOwnersInformation";
import CitizenRealtyInformation from "./CitizenRealtyInformation";

const CitizenRealtyDrawer = () => {
	const buildingObjectId = CitizenBuildingObjectStore.buildingObjectId;
	const address = CitizenBuildingObjectStore.selectedBuildingAddress;

	const citizenBuildingObjects = useQuery({
		queryFn: () => {
			if (buildingObjectId) {
				return getBuildingObjectsApi({ params: { buildingObjectIds: [buildingObjectId] } });
			}
			return [] as IBuildingObject[];
		},
		queryKey: [QueriesKeys.citizenBuildingObjects],
		enabled: !!buildingObjectId,
		cacheTime: 0,
	});

	useEffect(() => {
		if (citizenBuildingObjects.data && buildingObjectId) {
			CitizenBuildingObjectStore.openDrawer(citizenBuildingObjects?.data[0], address);
		}
	}, [citizenBuildingObjects.data, buildingObjectId]);

	const buildingType = getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType);
	const isLivingSpace = CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace;
	/* TEMPORARY HIDDEN */
	// const [openCitizenConfidantModal, setOpenCitizenConfidantModal] = useState(false);
	// const onClickAddTrustedPerson = () => {
	// 	setOpenCitizenConfidantModal(true);
	// 	CitizenBuildingObjectStore.setPreselectedRole("TRUSTED_PERSON");
	// };

	// const onClickAddTenant = () => {
	// 	setOpenCitizenConfidantModal(true);
	// 	CitizenBuildingObjectStore.setPreselectedRole("TENANT");
	// };

	const handleClose = () => {
		CitizenBuildingObjectStore.closeDrawer();

		const lastCitizen = ManagerStore.modalQueue.at(-1)?.type === "citizen";
		if (lastCitizen) {
			CitizensDrawerStore.openDrawer({ mode: "visible", user: CitizensDrawerStore.selectedUser });
		}
		ManagerStore.deleteModalFromQueue();
	};

	return (
		<Drawer
			backgroundBody={colors.surfaceNeutralBg4}
			title={buildingType}
			showOk={false}
			showCancel={false}
			width="700px"
			onClose={handleClose}
			visible={CitizenBuildingObjectStore.isOpenBuildingDrawer}>
			{/* TEMPORARY HIDDEN */}
			{/* <CitizenConfidantModal open={true} setOpen={setOpenCitizenConfidantModal} /> */}
			<Space size={30} direction="vertical">
				<CitizenHeaderInformation />
				<CitizenCommentsCollapse type="real-estate" />
				{isLivingSpace ? (
					<>
						<CitizenOwnersInformation />
						<CitizenRealtyInformation />
					</>
				) : (
					<>
						<CitizenRealtyInformation />
						<CitizenOwnersInformation />
					</>
				)}
				{/* TEMPORARY HIDDEN */}
				{/* <CitizenDocumentOwners /> */}
				{/* <CitizenBuildingObjectTruestedClientsInfo
					title="Доверенные лица собственников"
					roleType="TRUSTED_PERSON"
					setOpenDrawerAddTrustedPerson={onClickAddTrustedPerson}
					buttonText="Добавить доверенное лицо"
				/>

				<CitizenBuildingObjectTruestedClientsInfo
					title="Арендаторы"
					roleType="TENANT"
					setOpenDrawerAddTrustedPerson={onClickAddTenant}
					buttonText="Добавить арендатора"
				/> */}
			</Space>
		</Drawer>
	);
};

export default observer(CitizenRealtyDrawer);
