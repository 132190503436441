import React, { useState } from "react";

import { Space } from "tap2visit-ui-kit";

import CitizenRealtyCreate from "components/Citizens/components/CitizenRealtyCreate";

import { CitizenRealtyWrapper } from "../../../components/Citizens/style/Citizen.styled";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";
import RealEstateRows from "./RealEstateRows/RealEstateRows";

const CitizenRealty = () => (
	// const [openRealtyDrawer, setOpenRealtyDrawer] = useState(false);
	// const [openCreateRealty, setOpenCreateRealty] = useState(false);

	<Space size={8} direction="column" width="100%" align="start">
		{/*<CitizenCommerceDrawer open={openRealtyDrawer} setOpen={setOpenRealtyDrawer} />*/}
		{/*<CitizenParkingDrawer open={openRealtyDrawer} setOpen={setOpenRealtyDrawer} />*/}
		{/*<CitizenPantryDrawer open={openRealtyDrawer} setOpen={setOpenRealtyDrawer} />*/}
		{/* <CitizenRealtyCreate setOpen={setOpenCreateRealty} open={openCreateRealty} /> */}
		<CitizenDrawerTextHeaderBlock text={"Недвижимость"} />
		<Space width="100%" direction="column">
			<CitizenRealtyWrapper>
				<RealEstateRows />

				{/* Temporary hidden */}
				{/* <CitizenAddButton
						onClick={() => {
							setOpenCreateRealty(true);
						}}
						text={"Добавить недвижимость"}
					/> */}
			</CitizenRealtyWrapper>
		</Space>
	</Space>
);
export default CitizenRealty;
