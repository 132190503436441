import { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FormItem, Chip, showToast } from "tap2visit-ui-kit";

import { getRolesApi } from "api/api.realEstate";
import { TRoleTypes } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import getRoleTranslate from "utils/getRoleTranslate";

import PollsEditStore from "../store/PollsItem.store";

interface IPollsRoles {
	editable?: boolean;
	validationData?: {
		isRolesValid: boolean;
	};
	updateValidation?: (field: keyof IPollsRoles["validationData"], isValid: boolean) => void;
	isSubmitted?: boolean;
}

type TRoleItem = {
	selected: boolean;
	key: string;
	id: string;
	text: string;
};

const PollsRoles: FC<IPollsRoles> = ({ editable, updateValidation, validationData, isSubmitted }) => {
	const [roleItems, setRoleItems] = useState<TRoleItem[]>([]);
	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	useEffect(() => {
		const initializeRoleItems = () => {
			const initialSelectedRolesIds = new Set(PollsEditStore.poll.roleIds);
			// Todo in the future there will be all roles
			const excludedRoles = new Set<TRoleTypes>(["INTEREST_HOLDER", "TENANT", "TRUSTED_PERSON"]);

			return (roles.data ?? [])
				.filter((role) => !excludedRoles.has(role.name))
				.map((role) => ({
					key: role.name,
					id: role.id,
					selected: initialSelectedRolesIds.has(role.id),
					text: getRoleTranslate(role.name),
				}));
		};

		if (roles.data) {
			setRoleItems(initializeRoleItems());
		}
	}, [roles.data, PollsEditStore.poll.roleIds]);

	const handleRoleClick = (role: TRoleItem) => {
		PollsEditStore.changeRoles({ roleId: role.id });

		const updatedRoleItems = roleItems.map((item) => ({
			...item,
			selected: PollsEditStore.poll.roleIds.includes(item.id),
		}));

		setRoleItems(updatedRoleItems);

		const hasRoles = updatedRoleItems.some((item) => item.selected);

		if (!hasRoles) {
			showToast({ type: "danger", description: "Необходимо выбрать хотя бы одну роль" });
			updateValidation("isRolesValid", false);
		} else {
			updateValidation("isRolesValid", true);
		}
	};

	return (
		<FormItem label="Роли">
			<div>
				<Row>
					{roleItems.map((item) => (
						<Chip
							isError={isSubmitted && !validationData?.isRolesValid}
							key={item.key}
							onClick={() => handleRoleClick(item)}
							title={item.text}
							selected={item.selected}
							size="m"
							disabled={!editable}
						/>
					))}
				</Row>
				{editable && isSubmitted && !validationData?.isRolesValid && <ErrorText>Выберите роль</ErrorText>}
			</div>
		</FormItem>
	);
};

export default observer(PollsRoles);

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	row-gap: 8px;
`;

const ErrorText = styled.div`
	color: red;
	font-size: 12px;
	margin-top: 8px;
`;
