import React from "react";

import { getBuildingsBaseApi } from "api/api.gateway";
import { getComplexApi, getEntranceApi, getFloorsApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { toJS } from "mobx";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import TreeItem from "./components/Tree.item";
import RealEstateTreeStore, { TSelectedIds, TTypeOfRealEstate } from "./store/RealEstateTree.store";

type TTreeElement = { realEstateObject: JSX.Element; key: string; parentId: string; children?: TTreeElement[] };

const removeObjectFromSet = (set: Set<TSelectedIds>, id: string, parentId: string | null) => {
	const itemsArray = Array.from(set);
	for (const item of itemsArray) {
		if (item.id === id && item.parentId === parentId) {
			set.delete(item);
			return;
		}
	}
};

const hasSetAlreadySelectedId = (set: Set<TSelectedIds>, id: string) => {
	const itemsArray = Array.from(set);
	return itemsArray.some((item) => item.id === id);
};

const useRealEstateTreeData = (args?: { disabled?: boolean }) => {
	const [isSendToEveryone, setIsSendToEveryone] = React.useState(false);
	const location = useLocation();
	const allBuildingsIds = React.useRef<TSelectedIds[]>([]);
	const allEntrancesIds = React.useRef<TSelectedIds[]>([]);
	const allFloorsIds = React.useRef<TSelectedIds[]>([]);
	const allBuildingObjectsIds = React.useRef<TSelectedIds[]>([]);

	const hasInitialRealEstateSet = React.useRef(false);

	const complexes = useQuery({
		queryFn: () => getComplexApi(),
		queryKey: [QueriesKeys.complexes],
	});

	const buildings = useQuery({
		queryFn: () => getBuildingsBaseApi(),
		queryKey: [QueriesKeys.buildingsBase],
	});

	const entrances = useQuery({
		queryFn: () => getEntranceApi(),
		queryKey: [QueriesKeys.entrances],
	});

	const floors = useQuery({
		queryFn: () => getFloorsApi(),
		queryKey: [QueriesKeys.floors],
	});

	const buildingObjects = useQuery({
		queryFn: () => getBuildingObjectsApi(),
		queryKey: [QueriesKeys.buildingObjects],
	});

	React.useEffect(() => {
		allBuildingsIds.current =
			buildings.data?.map((v) => ({
				id: v.id,
				parentId: v.complexId,
			})) ?? [];
	}, [buildings.data]);

	React.useEffect(() => {
		allEntrancesIds.current =
			entrances.data?.map((v) => ({
				id: v.id,
				parentId: v.buildingId,
			})) ?? [];
	}, [entrances.data]);

	React.useEffect(() => {
		allFloorsIds.current =
			floors.data?.map((v) => ({
				id: v.id,
				parentId: v.entranceId,
			})) ?? [];
	}, [floors.data]);

	React.useEffect(() => {
		allBuildingObjectsIds.current =
			buildingObjects.data?.map((v) => ({
				id: v.id,
				parentId: v.floorId,
			})) ?? [];
	}, [buildingObjects.data]);

	/**
	 * Function which update children checkboxes after click on parent
	 */
	const changeChildrenChecked = (elements: TTreeElement[], setAction: "delete" | "add") => {
		const buildingsChildrenIds: TSelectedIds[] = [];
		const entranceChildrenIds: TSelectedIds[] = [];
		const floorsChildrenIds: TSelectedIds[] = [];
		const buildingObjectsChildrenIds: TSelectedIds[] = [];
		let childrenQueue = elements;

		while (childrenQueue.length) {
			const currentChild = childrenQueue.shift();
			if (currentChild.children) {
				childrenQueue = [...childrenQueue, ...currentChild.children];
			}
			const treeElement = {
				id: currentChild.key,
				parentId: currentChild.parentId,
			};

			const idFromBuilding = allBuildingsIds.current.some((v) => v.id === treeElement.id);

			if (idFromBuilding) {
				buildingsChildrenIds.push(treeElement);
				continue;
			}

			const idFromEntrance = allEntrancesIds.current.some((v) => v.id === treeElement.id);

			if (idFromEntrance) {
				entranceChildrenIds.push(treeElement);
				continue;
			}

			const idFromFloors = allFloorsIds.current.some((v) => v.id === treeElement.id);

			if (idFromFloors) {
				floorsChildrenIds.push(treeElement);
				continue;
			}

			const idFromBuildingsObject = allBuildingObjectsIds.current.some((v) => v.id === treeElement.id);

			if (idFromBuildingsObject) {
				buildingObjectsChildrenIds.push(treeElement);
				continue;
			}
		}

		const newBuildingsIds = new Set(toJS(RealEstateTreeStore.selectedBuildingsIds));
		const newEntrancesIds = new Set(toJS(RealEstateTreeStore.selectedEntrancesIds));
		const newFloorsIds = new Set(toJS(RealEstateTreeStore.selectedFloorsIds));
		const newBuildingObjectsIds = new Set(toJS(RealEstateTreeStore.selectedBuildingObjectsIds));

		buildingsChildrenIds.forEach(({ parentId, id }) => {
			if (setAction === "add") {
				if (!hasSetAlreadySelectedId(newBuildingsIds, id)) {
					newBuildingsIds.add({ parentId, id });
				}
			} else {
				removeObjectFromSet(newBuildingsIds, id, parentId);
			}
		});

		entranceChildrenIds.forEach(({ parentId, id }) => {
			if (setAction === "add") {
				if (!hasSetAlreadySelectedId(newEntrancesIds, id)) {
					newEntrancesIds.add({ parentId, id });
				}
			} else {
				removeObjectFromSet(newEntrancesIds, id, parentId);
			}
		});

		floorsChildrenIds.forEach(({ parentId, id }) => {
			if (setAction === "add") {
				if (!hasSetAlreadySelectedId(newFloorsIds, id)) {
					newFloorsIds.add({ parentId, id });
				}
			} else {
				removeObjectFromSet(newFloorsIds, id, parentId);
			}
		});

		buildingObjectsChildrenIds.forEach(({ parentId, id }) => {
			if (setAction === "add") {
				if (!hasSetAlreadySelectedId(newBuildingObjectsIds, id)) {
					newBuildingObjectsIds.add({ parentId, id });
				}
			} else {
				removeObjectFromSet(newBuildingObjectsIds, id, parentId);
			}
		});

		RealEstateTreeStore.updateAllSelectedEstate({
			selectedBuildingObjectsIds: Array.from(newBuildingObjectsIds),
			selectedFloorsIds: Array.from(newFloorsIds),
			selectedEntrancesIds: Array.from(newEntrancesIds),
			selectedBuildingsIds: Array.from(newBuildingsIds),
		});
	};

	/**
	 * Function which update parent checkboxes after unchecking a child
	 */
	const changeParentUnchecked = (element: TTreeElement, typeOfRealEstate: TTypeOfRealEstate) => {
		const parentElement = findParentElement(element, typeOfRealEstate);

		if (parentElement) {
			const parentType = getParentType(typeOfRealEstate);
			const isChecked = RealEstateTreeStore.getRealEstateIdsByType(parentType).some((v) => v.id === parentElement.key);

			if (isChecked) {
				RealEstateTreeStore.onClickCheckbox({ id: parentElement.key, type: parentType, parentId: element.parentId });
				changeParentUnchecked(parentElement, parentType);
			}
		}
	};

	const findParentElement = (element: TTreeElement, typeOfRealEstate: TTypeOfRealEstate): TTreeElement | null => {
		for (const complex of data) {
			if (typeOfRealEstate === "building" && complex.children?.some((child) => child.key === element.key)) {
				return complex;
			}

			for (const building of complex.children ?? []) {
				if (typeOfRealEstate === "entrance" && building.children?.some((child) => child.key === element.key)) {
					return building;
				}

				for (const entrance of building.children ?? []) {
					if (typeOfRealEstate === "floor" && entrance.children?.some((child) => child.key === element.key)) {
						return entrance;
					}

					for (const floor of entrance.children ?? []) {
						if (typeOfRealEstate === "buildingObject" && floor.children?.some((child) => child.key === element.key)) {
							return floor;
						}
					}
				}
			}
		}

		return null;
	};

	const getParentType = (typeOfRealEstate: TTypeOfRealEstate): TTypeOfRealEstate => {
		switch (typeOfRealEstate) {
			case "building":
				return "complex";
			case "entrance":
				return "building";
			case "floor":
				return "entrance";
			case "buildingObject":
				return "floor";
			default:
				throw new Error("Invalid type of real estate");
		}
	};

	const onChangeSendToEveryone = () => {
		const newStatusIsSendToEveryOne = !isSendToEveryone;
		setIsSendToEveryone(newStatusIsSendToEveryOne);
		if (newStatusIsSendToEveryOne) {
			RealEstateTreeStore.setInitialSelectedEstateWithParentId({
				selectedComplexesIds: complexes.data?.map((v) => ({ id: v.id, parentId: null })),
				selectedBuildingsIds: buildings.data?.map((v) => ({ id: v.id, parentId: v.complexId })),
				selectedEntrancesIds: entrances.data?.map((v) => ({ id: v.id, parentId: v.buildingId })),
				selectedFloorsIds: floors.data?.map((v) => ({ id: v.id, parentId: v.entranceId })),
				selectedBuildingObjectsIds: buildingObjects.data?.map((v) => ({ id: v.id, parentId: v.floorId })),
			});
		} else {
			RealEstateTreeStore.setInitialSelectedEstateWithParentId({
				selectedComplexesIds: [],
				selectedBuildingsIds: [],
				selectedEntrancesIds: [],
				selectedFloorsIds: [],
				selectedBuildingObjectsIds: [],
			});
		}
	};

	const changeCheckboxes = (element: TTreeElement, typeOfRealEstate: TTypeOfRealEstate) => {
		RealEstateTreeStore.onClickCheckbox({ id: element.key, parentId: element.parentId, type: typeOfRealEstate });
		const isChecked = RealEstateTreeStore.getRealEstateIdsByType(typeOfRealEstate).some((item) => item.id === element.key);
		const action = isChecked ? "add" : "delete";

		if (!isChecked) {
			changeParentUnchecked(element, typeOfRealEstate);
		}

		changeChildrenChecked(element?.children ?? [], action);
	};

	const onClickComplex = (args: { complexId: string }) => {
		changeCheckboxes(
			data.find((v) => v.key === args.complexId),
			"complex",
		);
	};

	const onClickBuilding = (args: { complexId: string; buildingId: string }) => {
		const complexChildren = data.find((v) => v.key === args.complexId);
		const buildingChildren = complexChildren.children.find((v) => v.key === args.buildingId);
		changeCheckboxes(buildingChildren, "building");
	};

	const onClickEntrance = (args: { complexId: string; buildingId: string; entranceId: string }) => {
		const complexChildren = data.find((v) => v.key === args.complexId);
		const buildingChildren = complexChildren.children.find((v) => v.key === args.buildingId);
		const entranceChildren = buildingChildren.children.find((v) => v.key === args.entranceId);
		changeCheckboxes(entranceChildren, "entrance");
	};

	const onClickFloor = (args: { complexId: string; buildingId: string; entranceId: string; floorId: string }) => {
		const complexChildren = data.find((v) => v.key === args.complexId);
		const buildingChildren = complexChildren.children.find((v) => v.key === args.buildingId);
		const entranceChildren = buildingChildren.children.find((v) => v.key === args.entranceId);
		const floorsChildren = entranceChildren.children.find((v) => v.key === args.floorId);

		changeCheckboxes(floorsChildren, "floor");
	};

	const onClickBuildingObject = (args: {
		complexId: string;
		buildingId: string;
		entranceId: string;
		floorId: string;
		buildingObjectId: string;
	}) => {
		const complexChildren = data.find((v) => v.key === args.complexId);
		const buildingChildren = complexChildren.children.find((v) => v.key === args.buildingId);
		const entranceChildren = buildingChildren.children.find((v) => v.key === args.entranceId);
		const floors = entranceChildren.children.find((v) => v.key === args.floorId);
		const buildingObjectItem = floors.children.find((v) => v.key === args.buildingObjectId);

		changeCheckboxes(buildingObjectItem, "buildingObject");
	};

	const data =
		complexes.data?.map((complex) => ({
			realEstateObject: (
				<TreeItem
					disabled={args?.disabled}
					text={complex.name}
					checked={RealEstateTreeStore.selectedComplexesIds.some((v) => v.id === complex.id)}
					onChangeChecked={() => {
						changeCheckboxes(
							data.find((v) => v.key === complex.id),
							"complex",
						);
					}}
				/>
			),
			parentId: null,
			key: complex.id,
			children:
				buildings.data
					?.filter((building) => building.complexId === complex.id)
					?.map((building) => ({
						key: building.id,
						parentId: building.complexId,
						realEstateObject: (
							<TreeItem
								disabled={args?.disabled}
								text={building.addressDto.value}
								checked={RealEstateTreeStore.selectedBuildingsIds?.some((v) => v.id === building.id)}
								onChangeChecked={() => {
									const complexChildren = data.find((v) => v.key === complex.id);
									const buildingChildren = complexChildren.children.find((v) => v.key === building.id);
									changeCheckboxes(buildingChildren, "building");
								}}
							/>
						),
						children:
							entrances.data
								?.filter((entrance) => entrance.buildingId === building.id)
								?.map((entrance) => ({
									key: entrance.id,
									parentId: entrance.buildingId,
									realEstateObject: (
										<TreeItem
											disabled={args?.disabled}
											text={`${entrance.number} Подьезд`}
											checked={RealEstateTreeStore.selectedEntrancesIds.some((v) => v.id === entrance.id)}
											onChangeChecked={() => {
												const complexChildren = data.find((v) => v.key === complex.id);
												const buildingChildren = complexChildren.children.find((v) => v.key === building.id);
												const entranceChildren = buildingChildren.children.find((v) => v.key === entrance.id);
												changeCheckboxes(entranceChildren, "entrance");
											}}
										/>
									),
									children:
										floors.data
											// eslint-disable-next-line max-nested-callbacks
											?.filter((floor) => floor.entranceId === entrance.id)
											// eslint-disable-next-line max-nested-callbacks
											?.map((floor) => ({
												key: floor.id,
												parentId: floor.entranceId,
												realEstateObject: (
													<TreeItem
														disabled={args?.disabled}
														text={`${floor.number} Этаж`}
														checked={RealEstateTreeStore.selectedFloorsIds.some((v) => v.id === floor.id)}
														onChangeChecked={() => {
															const complexChildren = data.find((v) => v.key === complex.id);
															const buildingChildren = complexChildren.children.find((v) => v.key === building.id);
															const entranceChildren = buildingChildren.children.find((v) => v.key === entrance.id);
															const florsItem = entranceChildren.children.find((v) => v.key === floor.id);
															changeCheckboxes(florsItem, "floor");
														}}
													/>
												),
												children:
													buildingObjects.data
														// eslint-disable-next-line max-nested-callbacks
														?.filter((buildingObject) => buildingObject.floorId === floor.id)
														// eslint-disable-next-line max-nested-callbacks
														?.map((buildingObject) => ({
															realEstateObject: (
																<TreeItem
																	isBuildingObject
																	disabled={args?.disabled}
																	text={`${buildingObject.number} Квартира`}
																	checked={RealEstateTreeStore.selectedBuildingObjectsIds?.some((v) => v.id === buildingObject.id)}
																	onChangeChecked={() => {
																		const complexChildren = data.find((v) => v.key === complex.id);
																		const buildingChildren = complexChildren.children.find((v) => v.key === building.id);
																		const entranceChildren = buildingChildren.children.find((v) => v.key === entrance.id);
																		const floors = entranceChildren.children.find((v) => v.key === floor.id);
																		const buildingObjectItem = floors.children.find((v) => v.key === buildingObject.id);

																		changeCheckboxes(buildingObjectItem, "buildingObject");
																	}}
																/>
															),
															parentId: buildingObject.floorId,
															key: buildingObject.id,
														})) ?? [],
											})) ?? [],
								})) ?? [],
					})) ?? [],
		})) ?? [];

	React.useEffect(() => {
		if (hasInitialRealEstateSet.current) {
			return;
		}

		const initialSelectedEstateExist =
			RealEstateTreeStore.initialSelectedComplexesIds.length ||
			RealEstateTreeStore.initialSelectedBuildingIds.length ||
			RealEstateTreeStore.initialSelectedEntrancesIds.length ||
			RealEstateTreeStore.initialSelectedFloorsIds.length ||
			RealEstateTreeStore.initialSelectedBuildingObjectsIds.length;

		const remoteDataIsLoaded =
			complexes.data?.length && buildings.data?.length && entrances.data?.length && floors.data?.length && buildingObjects.data?.length;

		if (data.length && initialSelectedEstateExist && remoteDataIsLoaded) {
			hasInitialRealEstateSet.current = true;

			RealEstateTreeStore.initialSelectedComplexesIds.forEach((complexId) => {
				onClickComplex({ complexId });
			});

			RealEstateTreeStore.initialSelectedBuildingIds.forEach((buildingId) => {
				const complexId = buildings.data.find((building) => building.id === buildingId).complexId;
				onClickBuilding({ complexId, buildingId });
			});

			RealEstateTreeStore.initialSelectedEntrancesIds.forEach((entranceId) => {
				const buildingId = entrances.data.find((entrance) => entrance.id === entranceId).buildingId;
				const complexId = buildings.data.find((building) => building.id === buildingId).complexId;
				onClickEntrance({ complexId, buildingId, entranceId });
			});

			RealEstateTreeStore.initialSelectedFloorsIds.forEach((floorId) => {
				const entranceId = floors.data.find((floor) => floor.id === floorId)?.entranceId;
				const buildingId = entrances.data.find((entrance) => entrance.id === entranceId).buildingId;
				const complexId = buildings.data.find((building) => building.id === buildingId).complexId;
				onClickFloor({ complexId, buildingId, entranceId, floorId });
			});

			RealEstateTreeStore.initialSelectedBuildingObjectsIds.forEach((buildingObjectId) => {
				const floorId = buildingObjects.data.find((buildingObject) => buildingObject.id === buildingObjectId)?.floorId;
				const entranceId = floors.data.find((floor) => floor.id === floorId).entranceId;
				const buildingId = entrances.data.find((entrance) => entrance.id === entranceId).buildingId;
				const complexId = buildings.data.find((building) => building.id === buildingId).complexId;
				onClickBuildingObject({ complexId, buildingId, entranceId, floorId, buildingObjectId });
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		RealEstateTreeStore.initialSelectedComplexesIds,
		RealEstateTreeStore.initialSelectedBuildingIds,
		RealEstateTreeStore.initialSelectedFloorsIds,
		RealEstateTreeStore.initialSelectedEntrancesIds,
		RealEstateTreeStore.initialSelectedBuildingObjectsIds,
		data,

		complexes.data,
		buildings.data,
		entrances.data,
		floors.data,
		buildingObjects.data,
	]);

	return {
		treeData: data,
		sendToEveryOneData: {
			value: isSendToEveryone,
			toggle: onChangeSendToEveryone,
		},
	};
};

export default useRealEstateTreeData;
