import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { BoldEdit } from "t2v-icons";
import { Button, colors, FormItem, Input, Space, Text, TextArea } from "tap2visit-ui-kit";

import { getUserApi } from "api/api.employee";
import { changeAppealApi, getAppealByIdApi } from "api/api.gateway";
import CitizenFiles from "components/Citizens/components/CitizenFiles";
import useNewFileState from "components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "components/Citizens/hooks/useUploadCitizenFiles";
import { Permissions } from "constants/constants.roles";
import { queryClient } from "index";
import { IAppeal } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsCreateTitleValidationStore from "pages/appeals/store/AppealsCreateTitleValidationStore";
import DrawerStore from "pages/appeals/store/Drawer.store";

import getFieldState from "../../utils/getState";

import { PersonalEditContent, PersonalEditFooter, PersonalEditWrapper } from "./AppealDrawer.styled";

const AppealDescription = () => {
	const validationFields = AppealsCreateTitleValidationStore.validationData;
	const errorMessageTitle = AppealsCreateTitleValidationStore.getMessageIfError("title");
	const errorMessageDescription = AppealsCreateTitleValidationStore.getMessageIfError("description");

	const [isViewAllDescription, setIsViewAllDescription] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [appealsFiles, setAppealsFiles] = useState(DrawerStore.selectedAppeals.files.map((file) => file.id));

	const { newFiles, setNewFiles, needToRemoveFileIds, setNeedToRemoveFileIds } = useNewFileState();

	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		bucket: "APPEAL_FILES",
	});

	const { data: user } = useQuery({
		queryFn: () => getUserApi(),
	});

	const changeAppeal = useMutation(changeAppealApi);

	const { data: appeal } = useQuery<IAppeal>({
		queryFn: () => getAppealByIdApi(DrawerStore.selectedAppeals.id),
		queryKey: [QueriesKeys.appealsById, DrawerStore.selectedAppeals.id],
		enabled: !!DrawerStore.selectedAppeals.id,
	});
	useEffect(() => {
		setAppealsFiles(DrawerStore.selectedAppeals.files.map((file) => file.id));
		setNewFiles([]);
		setNeedToRemoveFileIds([]);
	}, [DrawerStore.isOpenDrawer]);

	const changeAppealFunc = async () => {
		const { title, description } = DrawerStore.selectedAppeals;
		const isValidTitle = AppealsCreateTitleValidationStore.checkValidation({
			title,
			description,
		});

		if (!isValidTitle) return;

		const newFileIds = await uploadFiles();
		const allFileIds = [...appealsFiles, ...newFileIds];

		changeAppeal
			.mutateAsync({
				args: {
					title: appeal.title !== title ? title : undefined,
					description: appeal.description !== description ? description : undefined,
					fileIds: newFileIds.length > 0 ? allFileIds : undefined,
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				setIsEdit(false);
				setAppealsFiles(res.files.map((file) => file.id));
				setNewFiles([]);
				setNeedToRemoveFileIds([]);
				DrawerStore.setSelectedAppeals(res);
				queryClient.invalidateQueries([QueriesKeys.appealsList]);
				queryClient.invalidateQueries([QueriesKeys.appealsById]);
				queryClient.invalidateQueries([QueriesKeys.appealsListByIds]);
			});
	};

	const isAdmin = user.additionalProperties?.role === Permissions.ADMIN;
	const isNotCompletedOrArchived = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";
	const canEdit = !isEdit;

	const cancelChange = () => {
		DrawerStore.setSelectedAppeals({
			...DrawerStore.selectedAppeals,
			description: appeal.description,
			title: appeal.title,
		});
		setIsEdit(false);
		setNewFiles([]);
	};

	const changeInputValue = ({ key, value }: { key: string; value: string }) => {
		DrawerStore.setSelectedAppeals({ ...DrawerStore.selectedAppeals, [key]: value });
	};
	return (
		<Space width="100%" direction="column" align="start">
			<Space width="100%" justify="space-between">
				<Text h="h5" color={colors.textNeutralPrimary}>
					Описание
				</Text>
				{isAdmin && isNotCompletedOrArchived && canEdit && (
					<Button size="small" onClick={() => setIsEdit(true)} typeButton="secondary" icon={BoldEdit} shape="square" />
				)}
			</Space>
			{isEdit ? (
				<PersonalEditWrapper background={colors.surfaceNeutralBg2}>
					<PersonalEditContent>
						<FormItem
							state={getFieldState(validationFields.title)}
							message={errorMessageTitle}
							label="Заголовок"
							isRequiredStarLeft
							required>
							<Input
								state={getFieldState(validationFields.title)}
								onChange={(e) => changeInputValue({ key: "title", value: e.target.value })}
								value={DrawerStore.selectedAppeals.title}
							/>
						</FormItem>
						<FormItem
							state={getFieldState(validationFields.description)}
							message={errorMessageDescription}
							label="Описание"
							isRequiredStarLeft
							required>
							<TextArea
								onChange={(e) => changeInputValue({ key: "description", value: e.target.value })}
								state={getFieldState(validationFields.description)}
								style={{ width: "100%", background: "white" }}
								value={DrawerStore.selectedAppeals.description}
							/>
						</FormItem>

						<CitizenFiles
							newFiles={newFiles}
							setNewFiles={setNewFiles}
							fileIds={appealsFiles}
							needToRemoveFileIds={needToRemoveFileIds}
							setNeedToRemoveFileIds={setNeedToRemoveFileIds}
							title="Прикрепить фотографии"
							accept="image/jpeg,image/png,image/jpg,image/webp"
						/>
					</PersonalEditContent>

					<PersonalEditFooter>
						<Button typeButton="secondary" onClick={cancelChange}>
							Отменить
						</Button>
						<Button onClick={changeAppealFunc}>Сохранить</Button>
					</PersonalEditFooter>
				</PersonalEditWrapper>
			) : (
				<>
					<Text h="h4" color={colors.textNeutralPrimary}>
						{DrawerStore.selectedAppeals.title}
					</Text>
					<TextStyled isViewAllDescription={isViewAllDescription} type="large-regular" color={colors.textNeutralPrimary}>
						{DrawerStore.selectedAppeals.description}
					</TextStyled>
					{DrawerStore.selectedAppeals.description.length > 156 && (
						<Button style={{ marginLeft: "-10px" }} onClick={() => setIsViewAllDescription(!isViewAllDescription)} typeButton="link">
							{isViewAllDescription ? "Скрыть" : "Показать всё"}
						</Button>
					)}
					{DrawerStore.selectedAppeals.files.map((v) => v.id).length > 0 && (
						<CitizenFiles disabled fileIds={DrawerStore.selectedAppeals.files.map((v) => v.id)} title="Прикрепленные фотографии" />
					)}
				</>
			)}
		</Space>
	);
};

const TextStyled = styled(Text)<{ isViewAllDescription: boolean }>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${({ isViewAllDescription }) => (isViewAllDescription ? "none" : "3")};
	overflow: ${({ isViewAllDescription }) => (isViewAllDescription ? "none" : "hidden")};
`;
export default observer(AppealDescription);
