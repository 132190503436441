import styled from "styled-components";
import { Text } from "tap2visit-ui-kit";

const CheckedTitle = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px;
	border-bottom: 1px solid #12121229;
	align-items: center;
`;
const CheckedText = styled.div`
	border: 1px dashed #12121229;
	border-radius: 10px;
	padding: 6px;
	background: #be98411f;
`;

const AppealTextEmpty = styled(Text)`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const AppealTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const AppealConnectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #12121229;
	padding: 12px;
	width: 100%;
	align-items: center;
	cursor: pointer;
	align-items: center;
`;

const PersonalEditContent = styled.div`
	padding: 34px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const PersonalEditFooter = styled.div`
	display: flex;
	background: white;
	padding: 20px;
	justify-content: end;
	button:first-child {
		margin-right: 10px;
	}
`;
const PersonalEditWrapper = styled.div<{ background?: string }>`
	background: #be98411f;
	border-radius: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const StyledChipEmail = styled.div<{ isDisabled?: boolean; isSelected?: boolean }>`
	button {
		cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
		&:active {
			border: ${({ isDisabled, isSelected }) => (isDisabled && !isSelected ? "1px solid #1212123D" : "1px solid #99752C")};
		}
		p {
			color: ${({ isDisabled, isSelected }) => (isDisabled && !isSelected ? "#121212E6!important" : "#99752C!important")};
		}
		&:hover {
			border: ${({ isDisabled, isSelected }) => {
				if (isDisabled && isSelected) return "1px solid transparent!important";
				if (isDisabled) return "1px solid #1212123D!important";
				return "1px solid #BE9841B8";
			}};
		}
		&:focus-visible {
			border: ${({ isDisabled, isSelected }) => {
				if (isDisabled && isSelected) return "1px solid transparent!important";
				if (isDisabled) return "1px solid #1212123D!important";
				return "1px solid #BE9841B8";
			}};
			box-shadow: ${({ isDisabled }) => (isDisabled ? "none!important" : "0 0 0 2px #ccaf6b29")};
		}
		&:focus {
			border: ${({ isDisabled, isSelected }) => {
				if (isDisabled && isSelected) return "1px solid transparent!important";
				if (isDisabled) return "1px solid #1212123D!important";
				return "1px solid #BE9841B8";
			}};
			box-shadow: ${({ isDisabled }) => (isDisabled ? "none!important" : "0 0 0 2px #ccaf6b29")};
		}
	}
`;

export {
	CheckedTitle,
	CheckedText,
	AppealTextEmpty,
	AppealTitle,
	AppealConnectionWrapper,
	PersonalEditContent,
	PersonalEditFooter,
	StyledChipEmail,
	PersonalEditWrapper,
};
