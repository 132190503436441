import { useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { BoldEdit } from "t2v-icons";
import { Space, colors, Text, Button, Avatar, Tooltip } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.clients";
import { getUserApi } from "api/api.employee";
import { changeAppealApi, getAppealByIdApi } from "api/api.gateway";
import { downloadFileApi } from "api/api.storage";
import CitizensDrawer from "components/Citizens/Citizens.drawer";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import getCitizenStatus from "components/UserAvatarAndInitials/utils/getCitizenStatus";
import { Permissions } from "constants/constants.roles";
import { queryClient } from "index";
import type { IAppeal } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsCreateValidationStore from "pages/appeals/store/AppealsCreateValidationStore";
import DrawerStore from "pages/appeals/store/Drawer.store";
import { ReactComponent as SmartPhone } from "public/SmartPhone.svg";

import AppealsApplicantDrawer from "../DrawerCreate/AppealsApplicantDrawer";

import { PersonalEditContent, PersonalEditFooter, PersonalEditWrapper } from "./AppealDrawer.styled";

const AppealApplicant = () => {
	const [isEdit, setIsEdit] = useState(false);
	const [img, setImg] = useState("");

	const changeAppeal = useMutation(changeAppealApi);
	const isOwnerApplicant = DrawerStore.selectedAppeals.applicant.isOwner;

	const { status } = DrawerStore.selectedAppeals;

	const { data: user } = useQuery({
		queryFn: () => getUserApi(),
	});

	const { data: currentUser } = useQuery({
		queryFn: () => getUserByIdApi({ path: { id: DrawerStore.selectedAppeals.applicant.clientId } }),
		queryKey: [QueriesKeys.user, DrawerStore.selectedAppeals.applicant.clientId, isOwnerApplicant],
		enabled: !!DrawerStore.selectedAppeals.applicant.clientId && !!isOwnerApplicant,
		cacheTime: 0,
		staleTime: 0,
	});

	const { data: appeal } = useQuery<IAppeal>({
		queryFn: () => getAppealByIdApi(DrawerStore.selectedAppeals.id),
		queryKey: [QueriesKeys.appealsById, DrawerStore.selectedAppeals.id],
		enabled: !!DrawerStore.selectedAppeals.id,
	});

	useQuery({
		queryFn: () => {
			if (currentUser?.avatarId) {
				return downloadFileApi(currentUser?.avatarId);
			}
			setImg(undefined);
		},
		enabled: !!currentUser,
		queryKey: [QueriesKeys.downloadFileCitizen, currentUser?.avatarId],
		onSuccess: (data) => {
			if (data) {
				const blob = new Blob([data]);
				const imgLink = URL.createObjectURL(blob);
				setImg(imgLink);
				return;
			}
			setImg(undefined);
		},
	});

	const changeAppealFunc = async () => {
		const { firstName, middleName, lastName, emails, phoneNumbers, login, clientId } = DrawerStore.selectedAppeals.applicant;

		const isValid = AppealsCreateValidationStore.checkValidation({
			firstName,
			lastName,
			email: emails[0],
			phone: phoneNumbers[0]?.replace(/[-+()_\s]/g, ""),
			login: login?.replace(/[-+()_\s]/g, "") ?? undefined,
		});

		if (!isValid) return;

		changeAppeal
			.mutateAsync({
				args: {
					applicant: {
						firstName: appeal.applicant.firstName !== firstName ? firstName : undefined,
						lastName: appeal.applicant.lastName !== lastName ? lastName : undefined,
						middleName: appeal.applicant.middleName !== middleName ? middleName : undefined,
						email: appeal.applicant.emails[0] !== emails[0] ? emails[0] : undefined,
						phoneNumber: appeal.applicant.phoneNumbers[0] !== phoneNumbers[0] ? phoneNumbers[0] : undefined,
						login: appeal.applicant.login !== login && login ? login : undefined,
						clientId: DrawerStore.selectButtonIsOwner && appeal.applicant.clientId !== clientId && clientId ? clientId : undefined,
					},
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then(() => {
				queryClient.invalidateQueries([QueriesKeys.appealsById]);
				setIsEdit(false);
			});
	};

	const citizenUserStatus = getCitizenStatus({
		lastActivity: currentUser?.lastActivity,
		registration: currentUser?.registration,
	});

	const isAdmin = user.additionalProperties?.role === Permissions.ADMIN;
	const isNotCompletedOrArchived = status !== "COMPLETED" && status !== "ARCHIVED";
	const canEdit = !isEdit;
	const loginApplicant = DrawerStore.selectedAppeals.applicant.login;
	const isCreatedByClient = DrawerStore.selectedAppeals.isCreatedByClient;

	const caseOne = isOwnerApplicant;
	const caseTwo = !loginApplicant && !isOwnerApplicant;
	const caseThree = loginApplicant && !isOwnerApplicant;

	const isShowNotOwnerBlock = caseTwo || caseThree || isCreatedByClient;
	const isShowOwnerBlock = caseOne || isCreatedByClient;

	const cancelChange = () => {
		if (appeal) {
			DrawerStore.setSelectedAppeals({
				...DrawerStore.selectedAppeals,
				applicant: {
					...DrawerStore.selectedAppeals.applicant,
					firstName: appeal.applicant.firstName,
					lastName: appeal.applicant.lastName,
					middleName: appeal.applicant.middleName ?? "",
					phoneNumbers: appeal.applicant.phoneNumbers,
					emails: appeal.applicant.emails,
					login: appeal.applicant.login ?? "",
				},
			});
			setIsEdit(false);
		}
	};

	return (
		<Space width="100%" direction="column" align="start">
			<Space width="100%" justify="space-between">
				<Text h="h5" color={colors.textNeutralPrimary}>
					Заявитель
				</Text>
				{isAdmin && isNotCompletedOrArchived && canEdit && (
					<Button size="small" onClick={() => setIsEdit(true)} typeButton="secondary" icon={BoldEdit} shape="square" />
				)}
			</Space>
			{isEdit ? (
				<PersonalEditWrapper background={colors.surfaceNeutralBg2}>
					<PersonalEditContent>
						<AppealsApplicantDrawer isViewDrawer />
					</PersonalEditContent>
					<PersonalEditFooter>
						<Button typeButton="secondary" onClick={cancelChange}>
							Отменить
						</Button>
						<Button onClick={changeAppealFunc}>Сохранить</Button>
					</PersonalEditFooter>
				</PersonalEditWrapper>
			) : (
				<AppealEditWrapper>
					{isShowOwnerBlock && currentUser && (
						<AppealWrapper>
							<Text type="large-medium" color={colors.textNeutralPrimary}>
								Данные из системы
							</Text>
							<AppealOwnerWrapper onClick={() => CitizensDrawerStore.openDrawer({ mode: "visible", user: currentUser })}>
								<Tooltip style={{ zIndex: 1000 }} id="user_mobile_status">
									{citizenUserStatus.hint}
								</Tooltip>
								<Space width="100%">
									<Avatar
										size="32"
										text={`${currentUser?.lastName[0]} ${currentUser?.lastName[0]}`}
										img={img}
										badgeIcon={() => (
											<SmartPhoneIconContainer
												data-tooltip-id="user_mobile_status"
												data-tooltip-place="bottom"
												data-tooltip-variant="light">
												<SmartPhone width={10} height={10} fill={citizenUserStatus.color} />
											</SmartPhoneIconContainer>
										)}
										badgeIconBackground="white"
									/>
									<div>
										<Text type="table-cell-l-medium">
											{currentUser?.lastName} {currentUser?.firstName} {currentUser?.middleName}
										</Text>
										<Text type="table-cell-m-regular" color={colors.textNeutralSecondary}>
											{currentUser?.contacts.find((v) => v.type === "PHONE")?.value}
										</Text>
									</div>
								</Space>
							</AppealOwnerWrapper>
						</AppealWrapper>
					)}
					{isShowNotOwnerBlock && (
						<AppealWrapper>
							<Text type="large-medium" color={colors.textNeutralPrimary}>
								Данные из обращения
							</Text>
							<AppealConnectionWrapper>
								<AppealConnectionItem>
									<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
										ФИО
									</Text>
									<Text type="table-cell-m-medium" color={colors.textNeutralPrimary}>
										{`${DrawerStore.selectedAppeals.applicant.lastName} ${DrawerStore.selectedAppeals.applicant.firstName} ${DrawerStore.selectedAppeals.applicant.middleName}`}
									</Text>
								</AppealConnectionItem>
								<AppealConnectionItem>
									<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
										Логин в приложении
									</Text>
									<Text type="table-cell-m-medium" color={colors.textNeutralPrimary}>
										{DrawerStore.selectedAppeals.applicant?.login ?? "Нет данных"}
									</Text>
								</AppealConnectionItem>
								<AppealConnectionItem>
									<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
										Телефон для связи
									</Text>
									<Text type="table-cell-m-medium" color={colors.textNeutralPrimary}>
										{DrawerStore.selectedAppeals.applicant.phoneNumbers[0] ?? "Нет данных"}
									</Text>
								</AppealConnectionItem>
								<AppealConnectionItem isLastItem>
									<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
										Эл. почты
									</Text>
									<Text type="table-cell-m-medium" color={colors.textNeutralPrimary}>
										{DrawerStore.selectedAppeals.applicant.emails[0] ?? "Нет данных"}
									</Text>
								</AppealConnectionItem>
							</AppealConnectionWrapper>
						</AppealWrapper>
					)}
				</AppealEditWrapper>
			)}
			<CitizensDrawer />
		</Space>
	);
};

const SmartPhoneIconContainer = styled.div`
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const AppealOwnerWrapper = styled.div`
	padding: 8px 12px;
	border-radius: 10px;
	background: white;
	cursor: pointer;
`;
const AppealEditWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const AppealWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
`;
const AppealConnectionItem = styled.div<{ isLastItem?: boolean }>`
	padding: 12px;
	border-bottom: ${({ isLastItem }) => (isLastItem ? "none" : "1px solid #12121229")};
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
const AppealConnectionWrapper = styled.div`
	background: white;
	border-radius: 15px;
	width: 100%;
`;

export default observer(AppealApplicant);
