import { makeAutoObservable } from "mobx";

import type { IBuildingObject } from "interfaces/IBuilding";

class CitizenBuildingObjectStore {
	buildingObjectId: string | undefined = undefined;
	selectedBuilding: IBuildingObject | undefined = undefined;
	selectedBuildingAddress: string | undefined = undefined;
	isOpenBuildingDrawer = false;
	preselectedRole: "TRUSTED_PERSON" | "TENANT" = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	openDrawer = (buildingObject: IBuildingObject, address: string) => {
		this.isOpenBuildingDrawer = true;
		this.selectedBuilding = buildingObject;
		this.selectedBuildingAddress = address;
	};

	setBuildingId = (buildingObjectId: string, address: string) => {
		this.selectedBuildingAddress = address;
		this.buildingObjectId = buildingObjectId;
	};

	closeDrawer = () => {
		this.isOpenBuildingDrawer = false;
		this.selectedBuilding = undefined;
		this.selectedBuildingAddress = undefined;
		this.buildingObjectId = undefined;
	};

	setPreselectedRole = (role?: "TRUSTED_PERSON" | "TENANT") => {
		this.preselectedRole = role;
	};
}

export default new CitizenBuildingObjectStore();
