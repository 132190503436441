import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { showToast } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import useSavePoll from "pages/polls/hooks/useSavePoll";
import PollsItemStore from "pages/polls/store/PollsItem.store";

import PollsEditSendModalStore from "../../store/PollsSendModal.store";
import PollsPeriodOfTime from "../PollsPeriodOfTime";
import PollsRoles from "../PollsRoles";
import PollsTreeBuildings from "../PollsTreeBuildings";

import PollSendModalContent from "./components/PollsEditContent.sendUsing";

const PollsSendModal = () => {
	const savePoll = useSavePoll();
	const isTemplate = PollsItemStore.poll.isTemplate;

	const [validationData, setValidationData] = React.useState({
		isStartDateTimeValid: true,
		isEndDateTimeValid: true,
		isStartTimeValid: true,
		isEndTimeValid: true,
		isMethodValid: true,
		isRolesValid: false,
		isBuildingsValid: false,
	});
	const [isSubmitted, setIsSubmitted] = React.useState(false);

	const updateValidation = (field: keyof typeof validationData, isValid: boolean) => {
		setValidationData((prev) => ({
			...prev,
			[field]: isValid,
		}));
	};

	React.useEffect(() => () => RealEstateTreeStore.dispose(), []);

	useEffect(() => {
		const hasPreloadedBuildings =
			PollsItemStore.poll.complexIds.length > 0 ||
			PollsItemStore.poll.buildingIds.length > 0 ||
			PollsItemStore.poll.entranceIds.length > 0 ||
			PollsItemStore.poll.floorIds.length > 0 ||
			PollsItemStore.poll.buildingObjectIds.length > 0;

		if (hasPreloadedBuildings) {
			updateValidation("isBuildingsValid", true);
		}
	}, [PollsItemStore.poll]);

	useEffect(() => {
		const hasPreloadedRoles = PollsItemStore.poll.roleIds.length > 0;

		if (hasPreloadedRoles) {
			updateValidation("isRolesValid", true);
		}
	}, [PollsItemStore.poll]);

	const validateRoles = () => {
		const hasSelectedRoles = PollsItemStore.poll.roleIds.length > 0;
		if (!hasSelectedRoles) {
			updateValidation("isRolesValid", false);
			showToast({ type: "danger", description: "Выберите хотя бы одну роль" });
			return;
		}
		updateValidation("isRolesValid", true);
	};

	const validateMethod = () => {
		const hasMethod = PollsItemStore.poll.notificationTypes;

		if (!hasMethod) {
			updateValidation("isMethodValid", false);
			showToast({ type: "danger", description: "Выберите метод отправки" });
			return;
		}

		updateValidation("isMethodValid", true);
	};

	const validateBuildings = () => {
		const hasNoSelection =
			RealEstateTreeStore.selectedComplexesIds.length > 0 ||
			RealEstateTreeStore.selectedBuildingsIds.length > 0 ||
			RealEstateTreeStore.selectedEntrancesIds.length > 0 ||
			RealEstateTreeStore.selectedFloorsIds.length > 0 ||
			RealEstateTreeStore.selectedBuildingObjectsIds.length > 0;

		if (!hasNoSelection) {
			updateValidation("isBuildingsValid", false);
			showToast({ type: "danger", description: "Выберите хотя бы один элемент недвижимости" });
			return;
		}

		updateValidation("isBuildingsValid", true);
	};

	const validateAll = () => {
		validateMethod();
		validateRoles();
		validateBuildings();

		return Object.values(validationData).every((isValid) => isValid);
	};

	const handleSave = () => {
		setIsSubmitted(true);

		if (validateAll()) {
			savePoll();
		}
	};

	return (
		<BaseModal
			isVisible={PollsEditSendModalStore.isVisibleModal}
			onCancel={() => PollsEditSendModalStore.changeStatusModal(false)}
			onExit={() => PollsEditSendModalStore.changeStatusModal(false)}
			onOk={handleSave}
			title={isTemplate ? "Параметры отправки" : "Отправка активности"}
			contentContainerStyle={{ maxHeight: "80vh", overflow: "scroll" }}
			okText={isTemplate ? "Сохранить" : "Отправить"}
			cancelText="Отмена">
			<>
				<PollsPeriodOfTime validationData={validationData} updateValidation={updateValidation} />
				<Spacer px={16} />
				<PollSendModalContent validationData={validationData} updateValidation={updateValidation} />
				<Spacer px={16} />
				<PollsRoles editable={true} validationData={validationData} updateValidation={updateValidation} isSubmitted={isSubmitted} />
				<Spacer px={16} />
				<PollsTreeBuildings editable={true} validationData={validationData} validateBuildings={validateBuildings} />
			</>
		</BaseModal>
	);
};

export default observer(PollsSendModal);
