import { makeAutoObservable } from "mobx";

interface IModalManagerStore {
	type: "basement" | "citizen";
	id: string;
}

class ModalManagerStore {
	modalQueue: IModalManagerStore[] = [];
	constructor() {
		makeAutoObservable(this);
	}

	setModalInQueue = (type: IModalManagerStore["type"], id: string) => {
		if (this.modalQueue.length === 0) {
			this.modalQueue.push({ type, id });
			return;
		}

		const lastElement = this.modalQueue[this.modalQueue.length - 1];

		if (lastElement.id !== id) {
			this.modalQueue = [...this.modalQueue.filter(({ id: lastId }) => lastId !== id), { type, id }];
		}
	};

	deleteModalFromQueue = () => {
		const lastElement = this.modalQueue.at(-1);
		if (lastElement) {
			this.modalQueue.pop();
		}
	};
}

const ManagerStore = new ModalManagerStore();

export { ManagerStore };
