import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { Button, Checkbox, colors, Pagination, Search, Space, Text } from "tap2visit-ui-kit";

import { changeChildAppealApi, getAppealsListApi } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import PaginationContainer from "components/PaginationContainer";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { queryClient } from "index";
import { IAppeal, TAppealStatus, TGetAppealsListApiParams } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsStore from "pages/appeals/store/Appeals.store";
import DrawerStore from "pages/appeals/store/Drawer.store";
import { getAppealsEndings } from "pages/appeals/utils/getAppealsEndings";
import { useDebounce } from "pages/appeals/utils/useDebounce";

import { AppealConnectionWrapper, AppealTextEmpty, AppealTitle, CheckedText, CheckedTitle } from "./AppealDrawer.styled";

const TableFilterInstanceStore = new BuildingFilterStore();

const AppealConnectionModal = () => {
	const [createdBySearchTitle, setCreatedBySearchTitle] = useState("");
	const paginationData = useTablePaginationLogic({ clientSizeNumber: 7 });
	const [connectionAppeals, setConnectionAppeals] = useState([]);
	const [connectionAppealsAll, setConnectionAppealsAll] = useState([]);
	const debouncedSearchConnection = useDebounce(createdBySearchTitle, 1000);

	const { data: appeals } = useQuery({
		queryFn: () => {
			const statuses: TAppealStatus[] = ["NEW", "IN_REVIEW", "IN_PROGRESS"];

			const params: TGetAppealsListApiParams = {
				buildingObjectIds: TableFilterInstanceStore?.selectedBuildingObjectIds,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				titleSearch: debouncedSearchConnection,
				complexIds: TableFilterInstanceStore?.selectedComplexIds,
				buildingIds: TableFilterInstanceStore?.selectedBuildingsIds,
				entranceIds: TableFilterInstanceStore?.selectedEntrancesIds,
				floorIds: TableFilterInstanceStore?.selectedFloorsIds,
				hasChildAppeals: String(false),
				hasParentAppeal: String(false),
				status: statuses,
			};

			return getAppealsListApi({ params });
		},
		onSuccess(data) {
			setConnectionAppeals(
				data.content.map((appeal) => ({
					...appeal,
					checked: connectionAppealsAll.find((connection) => connection.id === appeal.id) ? true : false,
				})),
			);
		},
		queryKey: [
			QueriesKeys.appealsConnectionList,
			DrawerStore.selectedAppeals.id,
			AppealsStore.selectedTab,
			debouncedSearchConnection,
			TableFilterInstanceStore.selectedBuildingObjectIds,
			TableFilterInstanceStore?.selectedComplexIds,
			TableFilterInstanceStore?.selectedBuildingsIds,
			TableFilterInstanceStore?.selectedEntrancesIds,
			TableFilterInstanceStore?.selectedFloorsIds,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
	});

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.appealsConnectionList]);
	}, [debouncedSearchConnection, paginationData.clientsCurrentPage]);

	useEffect(() => {
		if (appeals) {
			paginationData.setClientsTotal(appeals.totalPages || 0);
		}
	}, [
		appeals?.totalPages,
		TableFilterInstanceStore.selectedComplexIds,
		TableFilterInstanceStore.selectedBuildingsIds,
		TableFilterInstanceStore.selectedEntrancesIds,
		TableFilterInstanceStore.selectedFloorsIds,
		TableFilterInstanceStore.selectBuildingObjects,
	]);

	useEffect(() => {
		paginationData.setClientsCurrentPage(1);
	}, [
		TableFilterInstanceStore.selectedComplexIds,
		TableFilterInstanceStore.selectedBuildingsIds,
		TableFilterInstanceStore.selectedEntrancesIds,
		TableFilterInstanceStore.selectedFloorsIds,
		TableFilterInstanceStore.selectedBuildingObjectIds,
		debouncedSearchConnection,
	]);

	const changeChildAppealFunc = useMutation(changeChildAppealApi);

	const createChildAppeal = () => {
		changeChildAppealFunc
			.mutateAsync({
				args: {
					childAppealIds: [...DrawerStore.selectedAppeals.childAppealIds, ...connectionAppealsAll.map((v) => v.id)],
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				DrawerStore.setSelectedAppeals(res);
				TableFilterInstanceStore.clearFilter();
				DrawerStore.setIsOpenConnectionAppeal(false);
				queryClient.invalidateQueries([QueriesKeys.appealsList]);
			});
	};

	useEffect(() => {
		if (DrawerStore.isOpenDrawer) {
			queryClient.invalidateQueries([QueriesKeys.appealsConnectionList]);
		}
	}, [DrawerStore.isOpenDrawer]);

	const changeChecked = (v: IAppeal) => {
		const isAlreadyChecked = connectionAppealsAll?.some((item) => item.id === v.id);

		const updatedConnectionAppealsAll = isAlreadyChecked
			? connectionAppealsAll.filter((item) => item.id !== v.id)
			: [...connectionAppealsAll, v];

		const map = connectionAppeals.map((val) => ({
			...val,
			checked: val.id === v.id ? !val.checked : val.checked,
		}));

		setConnectionAppealsAll(updatedConnectionAppealsAll);
		setConnectionAppeals(map);
	};

	const clearFunc = () => {
		setConnectionAppealsAll([]);
		setConnectionAppeals(
			connectionAppeals.map((v) => ({
				...v,
				checked: false,
			})),
		);
		TableFilterInstanceStore.clearFilter();
		setCreatedBySearchTitle("");
	};

	const closeModal = () => {
		DrawerStore.setIsOpenConnectionAppeal(false);
		clearFunc();
	};
	return (
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<BaseModal
				isVisible={DrawerStore.isOpenConnectionAppeal}
				title={"Добавить связь"}
				contentContainerStyle={{ height: "calc(80vh)", overflowY: "auto" }}
				onExit={closeModal}
				footerSlot={
					<Space size={24} width="100%" justify="flex-end">
						<Button onClick={clearFunc} typeButton="secondary">
							Сбросить
						</Button>
						<Button onClick={createChildAppeal}>Добавить связь</Button>
					</Space>
				}>
				<Space width="100%" align="start" direction="column">
					<Search
						placeholder="Введите название заголовка"
						value={createdBySearchTitle}
						onChangeValue={setCreatedBySearchTitle}
						sizeInput="medium"
						styleInputContainer={{ width: "100%" }}
						searchInputStyle={{ width: "100%" }}
					/>
					<TableFilter
						customSwitchOptions={{
							size: "m",
							isVisibleSwitch: false,
							isNotShowButton: true,
							isNotLabel: true,
						}}
					/>
					<CheckedTitle>
						<Text type="table-cell-m-regular" color={colors.textNeutralPrimary}>
							Выбрано
						</Text>
						<CheckedText>
							{connectionAppealsAll.length} {getAppealsEndings(connectionAppealsAll.length)}
						</CheckedText>
					</CheckedTitle>
					{connectionAppeals.length === 0 ? (
						<AppealTextEmpty type="table-cell-l-medium" color={colors.textNeutralSecondary}>
							Нет данных для отображения
						</AppealTextEmpty>
					) : (
						connectionAppeals.map((v) => (
							<AppealConnectionWrapper onClick={() => changeChecked(v)} key={v.id}>
								<AppealTitle>
									<Text type="table-cell-m-regular" color={colors.textNeutralSecondary}>
										{dayjs(v.createdAt).format("DD.MM.YYYY")}
									</Text>
									<Text type="table-cell-l-medium" color={colors.textNeutralPrimary}>
										№{v.number} {v.title}
									</Text>
									<Text type="table-cell-m-regular" color={colors.textNeutralSecondary}>
										{v.buildingObjectAddress?.addressValue}
									</Text>
								</AppealTitle>
								<Checkbox checked={v.checked} />
							</AppealConnectionWrapper>
						))
					)}
					<PaginationContainer style={{ width: "100%" }}>
						<Pagination
							onChangeSize={paginationData.setClientsSize}
							currentPage={paginationData.clientsCurrentPage}
							setCurrentPage={paginationData.setClientsCurrentPage}
							total={paginationData.clientsTotal}
						/>
					</PaginationContainer>
				</Space>
			</BaseModal>
		</TableFilterContext.Provider>
	);
};

export default observer(AppealConnectionModal);
