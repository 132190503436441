import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Avatar } from "tap2visit-ui-kit";

import CitizenBuildingObjectStore from "components/Citizens/store/CitizenBuildingObject.store";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import { ManagerStore } from "components/Citizens/store/ModalManagerStore";
import { Spacer } from "components/layout/Spacer";
import type { IClient } from "interfaces/IClient";
import { RoleTypes } from "interfaces/IRole";

import {
	CitizenRealtyDivider,
	CitizenRealtyWrapper,
	CitizenRealtyWrapperFlexDrawer,
	CitizenRealtyWrapperSpace,
} from "../../../components/Citizens/style/Citizen.styled";
import useBuildingClientsByRole from "../hooks/useBuildingClientsByRole";

import { CitizenDrawerTextHeaderBlock, CitizenDrawerBlockItemHeader, CitizenDrawerBlockItemDescription } from "./CitizenTexts";

const CitizenOwnersInformation = observer(() => {
	const buildingObjectClients = useBuildingClientsByRole({ role: RoleTypes.OWNER });

	const handleOpenCitizenDrawer = (client: IClient) => {
		CitizensDrawerStore.openDrawer({ mode: "visible", user: client });
		ManagerStore.setModalInQueue("citizen", client.id);
		CitizenBuildingObjectStore.closeDrawer();
	};

	return (
		<>
			<CitizenDrawerTextHeaderBlock text="Собственники" />
			<Spacer px={12} />
			<Container>
				{buildingObjectClients?.map((client, idx) => {
					const phone = client.contacts?.find((contact) => contact.type === "PHONE")?.value;
					const isFirstElement = idx === 0;

					return (
						<div style={{ cursor: "pointer" }} onClick={() => handleOpenCitizenDrawer(client)}>
							{!isFirstElement && <CitizenRealtyDivider />}

							<CitizenRealtyFlexRent>
								<CitizenRealtyWrapperFlexDrawer>
									<Avatar size="32" firstName={client.lastName?.[0]} lastName={client.firstName?.[0]} />
									<CitizenRealtyWrapperSpace size={1} direction="vertical">
										<CitizenDrawerBlockItemHeader text={client.fullName} />
										<CitizenDrawerBlockItemDescription text={phone} />
									</CitizenRealtyWrapperSpace>
								</CitizenRealtyWrapperFlexDrawer>
								{/* TEMPORARY HIDDEN */}
								{/* <Button icon={MessagesPlus} shape="square" size="medium" typeButton="secondary" /> */}
							</CitizenRealtyFlexRent>
						</div>
					);
				})}
			</Container>
		</>
	);
});
const CitizenRealtyFlexRent = styled(CitizenRealtyWrapper)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	border-radius: 0;
`;

const Container = styled.div`
	border-radius: 10px;
	overflow: hidden;
`;

export default CitizenOwnersInformation;
