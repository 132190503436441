import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";
import { colors, Text, Tooltip } from "tap2visit-ui-kit";

import getCitizenStatus from "components/UserAvatarAndInitials/utils/getCitizenStatus";
import { IApplicant } from "interfaces/IAppeals";
import { ReactComponent as SmartPhone } from "public/SmartPhone.svg";

interface IAppealCloseApplicant {
	applicant: IApplicant;
	isTreeApplicant?: boolean;
}

const AppealCloseApplicant = ({ applicant, isTreeApplicant }: IAppealCloseApplicant) => {
	const citizenUserStatus = getCitizenStatus({
		lastActivity: applicant?.lastActivity,
		registration: applicant?.registration,
	});

	return (
		<div>
			<Tooltip style={{ zIndex: 1000 }} id={isTreeApplicant ? `user_${applicant.clientId}_status` : "user_status"}>
				{citizenUserStatus.hint}
			</Tooltip>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						ФИО
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.lastName} {applicant.firstName} {applicant.middleName}
					</AppealText>
				</div>
				<SmartPhone
					data-tooltip-id={isTreeApplicant ? `user_${applicant.clientId}_status` : "user_status"}
					data-tooltip-place="bottom"
					data-tooltip-variant="light"
					width={16}
					height={16}
					fill={citizenUserStatus.color}
				/>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Логин в приложении
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.login ?? "Нет данных"}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Телефон для связи
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.phoneNumbers[0] ?? "Нет данных"}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Эл. почты
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.emails[0] ?? "Нет данных"}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
		</div>
	);
};

const AppealText = styled(Text)`
	width: 320px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
`;

const AppealCloseApplicantWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #12121229;
	padding: 8px 16px;
`;

export default AppealCloseApplicant;
