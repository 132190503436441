import { useEffect, useState } from "react";

import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, showToast } from "tap2visit-ui-kit";

import { getBuildingsApi, getComplexesApi } from "api/api.arcus-integration";
import { createImportTaskApi, validateFileUploadApi } from "api/api.storage";
import { QueriesKeys } from "interfaces/queriesKeys";

import UpdateDataStore from "../store/UpdateDataStore";
import { ConfirmButtonWrapper, FormListWrapper } from "../UpdateData.styled";

import UpdateDataFile from "./UpdateData.file";
import { UpdateDataFormSelect } from "./UpdateData.formSelect";

interface IApiError {
	response: {
		data: {
			message: string;
		};
	};
}

const UpdateDataForm = () => {
	const [isFileUploaded, setIsFileUploaded] = useState(undefined);
	const [errorText, setErrorText] = useState<string | undefined>(undefined);

	const focusedComplex = UpdateDataStore.complexesIds.find((item) => item.focused);
	const focusedBuilding = UpdateDataStore.buildingIds.find((item) => item.focused);
	const focusedTypeData = toJS(UpdateDataStore.getRequestData().buildingTypes[0]);
	const fileId = UpdateDataStore.getUploadBucketFileId();

	const queryClient = useQueryClient();

	const { data: complexes } = useQuery({
		queryFn: getComplexesApi,
		enabled: UpdateDataStore.complexesIds.length === 0,
		queryKey: [QueriesKeys.arcusComplexes],
	});

	const { data: buildings } = useQuery({
		queryFn: () => getBuildingsApi({ params: { complexId: toJS(focusedComplex).key } }),
		enabled: !!focusedComplex,
		queryKey: [QueriesKeys.arcusBuildings, UpdateDataStore.complexesIds],
	});

	const validateFile = useMutation(validateFileUploadApi, {
		onSuccess: () => {
			setErrorText(undefined);
			setIsFileUploaded(true);
		},
		onError: (error: IApiError) => {
			setErrorText(error.response?.data?.message);
			setIsFileUploaded(false);
		},
	});

	useEffect(() => {
		if (Array.isArray(complexes)) {
			const mappedComplexes = complexes?.map((complex) => ({
				focused: false,
				key: complex.id,
				text: complex.name,
			}));
			UpdateDataStore.setComplexes(mappedComplexes);
		}
	}, [complexes]);

	useEffect(() => {
		if (Array.isArray(buildings)) {
			const mappedBuildings = buildings?.map((building) => ({
				focused: false,
				key: building.id,
				text: building.name,
			}));
			UpdateDataStore.setBuildings(mappedBuildings);
		}
	}, [buildings]);

	const createImportTask = useMutation(createImportTaskApi, {
		onSuccess: (data) => {
			showToast({ description: "Добавлен в статус загрузки", type: "success" });
			UpdateDataStore.resetData();
			setIsFileUploaded(false);
			queryClient.invalidateQueries([QueriesKeys.payments]);
		},
		onError: (error) => {
			showToast({ description: "Ошибка создания задачи импорта", type: "danger" });
		},
	});

	const handleConfirmUpload = () => {
		if (fileId && focusedBuilding && focusedTypeData) {
			createImportTask.mutate({
				buildingId: focusedBuilding?.key,
				fileId: UpdateDataStore.getUploadBucketFileId(),
				buildingType: focusedTypeData,
			});
		}
	};

	useEffect(() => {
		if (fileId && focusedBuilding && focusedTypeData) {
			validateFile.mutate({
				buildingId: focusedBuilding.key,
				id: fileId,
				buildingType: focusedTypeData,
			});
		}
	}, [focusedBuilding, focusedTypeData]);

	useEffect(() => () => UpdateDataStore.resetData(), []);

	const isAllInputsChanged = !!focusedComplex && !!focusedBuilding && !!focusedTypeData;

	return (
		<FormListWrapper>
			<UpdateDataFormSelect
				disabled={isFileUploaded}
				items={UpdateDataStore.complexesIds}
				setItems={(items) => UpdateDataStore.setComplexes(items)}
				label="ЖК"
			/>
			<UpdateDataFormSelect
				disabled={isFileUploaded || !focusedComplex}
				items={UpdateDataStore.buildingIds}
				setItems={(items) => UpdateDataStore.setBuildings(items)}
				label="Дом/Литер/Корпус"
			/>
			<UpdateDataFormSelect
				disabled={isFileUploaded || !focusedBuilding}
				items={UpdateDataStore.getTypesData()}
				setItems={(item) => UpdateDataStore.setBuildingTypes(item)}
				label="Тип данных"
			/>

			<UpdateDataFile
				disabled={!isAllInputsChanged}
				setIsFileUploading={setIsFileUploaded}
				errorText={errorText}
				setErrorText={setErrorText}
			/>

			<ConfirmButtonWrapper>
				<Button disabled={!isFileUploaded} typeButton="primary" onClick={handleConfirmUpload}>
					Подтвердить загрузку
				</Button>
			</ConfirmButtonWrapper>
		</FormListWrapper>
	);
};

export default observer(UpdateDataForm);
