import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Badge, Button, colors, Search, Space, Text } from "tap2visit-ui-kit";

import { postMessageView } from "api/api.chats";
import { getComplexesApi, getListChat } from "api/api.gateway";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";

import AvatarChat from "./AvatarChat";

const ChatList = observer(() => {
	const navigate = useNavigate();
	const [query, setQuery] = useState("");
	const [complexId, setComplexId] = useState("");

	const user = useQuery({
		queryFn: () => getListChat({ params: { query, complexId } }),
		queryKey: [QueriesKeys.chatList, query, complexId],
	});
	const complexes = useQuery({
		queryFn: () => getComplexesApi({ params: { requestStatus: "NEW", announcementStatus: "NEW" } }),
		queryKey: [QueriesKeys.advancedComplexes],
	});

	const postMessageViewFunc = useMutation((chatId: string) => postMessageView({ chatId }));
	return (
		<ChatListWrapper>
			<ChatListTitle h="h4">Чат</ChatListTitle>
			<ChatListButtonWrapper>
				{complexes.data?.map((v) => (
					<Button
						onClick={() => {
							if (complexId) setComplexId("");
							else setComplexId(v.id);
						}}
						size="small"
						typeButton={complexId === v.id ? "primary" : "secondary"}>
						{v.name}
					</Button>
				))}
			</ChatListButtonWrapper>
			<ChatListItemWrapper>
				<SearchWrapper>
					<Search searchInputStyle={{ width: "100%" }} value={query} onChangeValue={(value) => setQuery(value)} sizeInput="medium" />
				</SearchWrapper>
				{user.data?.content
					?.filter((v) => v.isEnabled)
					.map((v) => (
						<ListWrapper
							onClick={() => {
								navigate(`chat/${v.id}`);
								postMessageViewFunc.mutateAsync(v.id).then(() => {
									queryClient.invalidateQueries([QueriesKeys.chatList]);
								});
							}}>
							<Space>
								<AvatarChat lastName={v.name.split("")[1]} firstName={v.name.split("")[0]} avatarId={v.avatarId} />
								<div>
									<TextStyled type="base-bold">{v.name}</TextStyled>
									<TextStyled color="#12121299" type="base-regular">
										{v.lastMessage?.text}
									</TextStyled>
								</div>
							</Space>

							{v.unViewedMessagesAmount && v.name ? <Badge counter={v.unViewedMessagesAmount} type="counter" /> : ""}
						</ListWrapper>
					))}
			</ChatListItemWrapper>
		</ChatListWrapper>
	);
});

const TextStyled = styled(Text)`
	white-space: nowrap;
	overflow: hidden;
	max-width: 210px;
	width: 210px;
	text-overflow: ellipsis;
`;

const ListWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid ${colors.strokeDivider};
	padding: 16px 24px;
	cursor: pointer;
	justify-content: space-between;
`;
const SearchWrapper = styled.div`
	padding: 18px;
`;
const ChatListItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 80vh;
	overflow: auto;
`;
const ChatListButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 18px;
	border-bottom: 1px solid ${colors.strokeDivider};
`;
const ChatListTitle = styled(Text)`
	padding: 18px;
	border-bottom: 1px solid ${colors.strokeDivider};
`;
const ChatListWrapper = styled.div`
	width: 320px;
	border-right: 1px solid ${colors.strokeDivider};
	height: 105vh;
	position: fixed;
	top: 0;
	background: ${colors.surfaceNeutralBgWhite};
	left: 257px;
	overflow: auto;
`;
export default ChatList;
