import React, { FC } from "react";

import { useQuery } from "react-query";

import { getAliasesApi } from "api/api.aliases";
import { getBuildingObjectIdsByUserIdApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";

import CitizensDrawerStore from "../../../../components/Citizens/store/Citizens.drawer.store";

import RealEstateRowsItem from "./RealEstateRows.item";

const RealEstateRows = () => {
	const citizenBuildingObjectsIds = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const citizenAliasesIdsMap = citizenBuildingObjectsIds.data?.map((i) => i.aliasId);

	const citizenAliases = useQuery({
		queryFn: () => {
			if (citizenAliasesIdsMap.length) {
				return getAliasesApi({ params: { aliasIds: citizenAliasesIdsMap } });
			}
			return [];
		},
		queryKey: [QueriesKeys.aliases, ...(citizenAliasesIdsMap ?? [])],
		enabled: citizenBuildingObjectsIds.isFetched,
	});

	return (
		<>
			{citizenBuildingObjectsIds.data?.map((objectsIds) => (
				<RealEstateRowsItem objectsIds={objectsIds} citizenAliases={citizenAliases.data} />
			))}
		</>
	);
};

export default RealEstateRows;
