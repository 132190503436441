import { IUser, IUserList, IUserMe } from "interfaces/User/IUser";
import { isMockEnabled } from "mocks/mocks.constants";
import * as usersMock from "mocks/mocks.users";

import apiClient from "./api.client";

export type TGetUsersApi = (args?: {
	fio?: string;
	roles?: string[];
	email?: string;
	mobilePN?: string;
	showDismissed?: boolean;
	page: number;
	size: number;
	sort?: { key: string; direction: string }[];
}) => Promise<IUserList>;

export const getUsersApi: TGetUsersApi = async (args) => {
	try {
		if (isMockEnabled) {
			return usersMock.allUsers;
		}
		const { sort, roles, ...otherParams } = args;

		let formattedRoles = "";

		let path = "eaa/v1/users";
		let sortParams = "?";

		if (args.roles.length) {
			formattedRoles = args.roles.join(",");
		}
		if (args.sort?.length) {
			args.sort.forEach((sort, index) => {
				if (!sort.direction) {
					return;
				}

				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params: {
				...otherParams,
				roles: formattedRoles,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateUserApi = (args: { body: Omit<IUser, "isPasswordSet" | "id"> }) => Promise<IUser>;

export const createUserApi: TCreateUserApi = async (args) => {
	try {
		const res = await apiClient.post("eaa/v1/users", {
			email: args.body.email,
			mobilePN: args.body.mobilePN,
			firstName: args.body.firstName,
			lastName: args.body.lastName,
			middleName: args.body.middleName,
			role: args.body.role,
			comment: args.body.comment,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetUserMe = () => Promise<IUserMe>;

export const getUserApi: TGetUserMe = async () => {
	try {
		const res = await apiClient.get("eaa/v1/users/info");

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetUserByIdApi = (args: { params: { id: string } }) => Promise<IUser>;

export const getUserByIdApi: TGetUserByIdApi = async (args) => {
	try {
		const res = await apiClient.get(`eaa/v1/users/${args.params.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateUserApi = (args: { path: { id: string }; body: Partial<IUser> }) => Promise<IUser>;

export const updateUserApi: TUpdateUserApi = async (args) => {
	try {
		const res = await apiClient.patch(`eaa/v1/users/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteUserApi = (args: { path: { id: string } }) => Promise<IUser>;

export const deleteUserApi: TDeleteUserApi = async (args) => {
	try {
		const res = await apiClient.post(`eaa/v1/users/${args.path.id}/profile/dismiss`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
