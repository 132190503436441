import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { DropResult } from "react-beautiful-dnd";
import { getUid } from "tap2visit-ui-kit";

import {
	IQuestionCreateDto,
	IQuestionDto,
	IVoteCreateDto,
	IVoteDto,
	IVoteUpdateDto,
	TNotificationType,
	TTimeLifeType,
} from "interfaces/IVote";
import deepCopy from "utils/deepCopy";
import last from "utils/last";

export type TPollTimeType = "startDateTime" | "endDateTime";

const initialPoll: IVoteDto = {
	id: "",
	isDeleted: false,
	status: undefined,
	timeLifeValue: 1,
	timeLifeType: "DAY",
	name: "",
	type: "POLL",
	notificationTypes: ["PUSH"],
	startDateTime: null,
	endDateTime: null,
	roleIds: [],
	complexIds: [],
	buildingIds: [],
	entranceIds: [],
	floorIds: [],
	buildingObjectIds: [],
	isTemplate: false,
	questions: [
		{
			answerOptions: [],
			id: "",
			type: "SINGLE_CHOICE",
			value: "",
			isFree: false,
		},
	],
};

class PollsItemStore {
	poll = initialPoll;
	isPoppulate = false;
	haslocationBeenChecked = false;
	isLoadingTemplate = false;

	constructor() {
		makeAutoObservable(this);
	}

	get pollCreateDto(): IVoteCreateDto {
		const questions: IQuestionCreateDto[] = this.poll.questions.map((question) => ({
			value: question.value,
			type: question.type,
			answerOptions: question.answerOptions.map((option) => ({
				value: option.value,
				isFree: option.isFree,
			})),
		}));

		return {
			name: this.poll.name,
			type: this.poll.type,
			notificationTypes: this.poll.notificationTypes,
			timeLifeType: this.poll.timeLifeType,
			timeLifeValue: this.poll.timeLifeValue,
			startDateTime: this.poll.startDateTime || null,
			endDateTime: this.poll.endDateTime || null,
			roleIds: this.poll.roleIds,
			complexIds: this.poll.complexIds,
			buildingIds: this.poll.buildingIds,
			entranceIds: this.poll.entranceIds,
			floorIds: this.poll.floorIds,
			buildingObjectIds: this.poll.buildingObjectIds,
			isTemplate: this.poll.isTemplate,
			questions: questions,
		};
	}

	get pollUpdateDto(): IVoteUpdateDto {
		const questions: IQuestionCreateDto[] = this.poll.questions.map((question) => {
			const questionObject = {
				id: question.id,
				value: question.value,
				type: question.type,
				answerOptions: question.answerOptions.map((option) => {
					const answerOption = {
						id: option.id,
						value: option.value,
						isFree: option.isFree,
					};

					if (answerOption.id.startsWith("new_")) {
						delete answerOption.id;
					}

					return answerOption;
				}),
			};

			if (questionObject.id.startsWith("new_")) {
				delete questionObject.id;
			}

			return questionObject;
		});

		return {
			id: this.poll.id,
			name: this.poll.name,
			type: this.poll.type,
			notificationTypes: this.poll.notificationTypes,
			timeLifeType: this.poll.timeLifeType,
			timeLifeValue: this.poll.timeLifeValue,
			startDateTime: this.poll.startDateTime,
			endDateTime: this.poll.endDateTime,
			roleIds: this.poll.roleIds,
			complexIds: this.poll.complexIds,
			buildingIds: this.poll.buildingIds,
			entranceIds: this.poll.entranceIds,
			floorIds: this.poll.floorIds,
			buildingObjectIds: this.poll.buildingObjectIds,
			isTemplate: this.poll.isTemplate,
			questions: questions,
		};
	}

	setCheckedLocation = () => {
		this.haslocationBeenChecked = true;
	};

	setIsLoadingTemplate = (value: boolean) => {
		this.isLoadingTemplate = value;
	};

	toggleIsTemplate = (value: boolean) => {
		this.poll.isTemplate = value;
	};

	changeTimeLife = (args: { timeLifeValue?: number; timeLifeType?: TTimeLifeType }) => {
		this.poll.startDateTime = "";
		this.poll.endDateTime = "";

		if (args.timeLifeValue) {
			this.poll.timeLifeValue = args.timeLifeValue;
		}

		if (args.timeLifeType) {
			this.poll.timeLifeType = args.timeLifeType;
		}
	};

	changeStartEndTimePoll = (args: { type: TPollTimeType; typeOfTime: "time" | "day"; value: dayjs.Dayjs }) => {
		this.poll.timeLifeType = null;
		this.poll.timeLifeValue = null;

		let time = this.poll[args.type] ? dayjs(this.poll[args.type]) : dayjs();
		if (args.typeOfTime === "day") {
			time = time.set("date", Number(args.value.date())).set("month", Number(args.value.month())).set("year", Number(args.value.year()));
		}

		if (args.typeOfTime === "time") {
			time = time.set("hour", Number(args.value.hour())).set("minute", Number(args.value.minute()));
		}

		this.poll[args.type] = time.toDate().toISOString();
	};

	changeNotificationTypes = (args: { notificationType: TNotificationType }) => {
		const notificationTypes = [...this.poll.notificationTypes];
		const notificationTypeIndex = notificationTypes.findIndex((n) => n === args.notificationType);

		if (notificationTypeIndex === -1) {
			notificationTypes.push(args.notificationType);
		} else {
			notificationTypes.splice(notificationTypeIndex, 1);
		}

		this.poll.notificationTypes = notificationTypes;
	};

	changeRoles = ({ roleId }: { roleId: string }) => {
		const roleIndex = this.poll.roleIds.indexOf(roleId);

		if (roleIndex === -1) {
			this.poll.roleIds.push(roleId);
		} else {
			this.poll.roleIds.splice(roleIndex, 1);
		}
	};

	/**
	 * description fill poll with already exist poll/vote
	 */
	populatePoll(args: { poll: IVoteDto; mode?: "new" | "edit" }) {
		this.poll = args?.mode === "new" ? this.getPollWithResettedIds(args.poll) : args.poll;
		this.isPoppulate = true;
	}

	changePoll<
		T extends keyof Omit<
			IVoteDto,
			"questions" | "timeLifeType" | "timeLifeValue" | "notificationTypes" | "roles" | "startDateTime" | "endDateTime"
		>,
	>(args: { key: T; value: IVoteDto[T]; payload?: { id?: string } }) {
		this.poll[args.key] = args.value;
	}

	// #region question crud logic
	changePollQuestion = (args: { question: Partial<IQuestionDto> & { id: string } }) => {
		const editedQuestionIndex = this.poll.questions.findIndex((question) => question.id === args.question.id);
		const questions = deepCopy(this.poll.questions);

		const updatedQuestion = {
			...this.poll.questions[editedQuestionIndex],
			...args.question,
			answerOptions:
				args.question.type === "FREE_CHOICE"
					? [{ id: getUid("free_choice"), value: null, isFree: true }]
					: this.poll.questions[editedQuestionIndex].answerOptions,
		};

		questions.splice(editedQuestionIndex, 1, updatedQuestion);
		this.poll.questions = questions;
	};

	removePollQuestion = (args: { question: { id: string } }) => {
		const editedQuestionIndex = this.poll.questions.findIndex((question) => question.id === args.question.id);

		this.poll.questions = [...this.poll.questions.slice(0, editedQuestionIndex), ...this.poll.questions.slice(editedQuestionIndex + 1)];
	};

	addPollQuestion = () => {
		this.poll.questions = [
			...this.poll.questions,
			{
				answerOptions: [],
				id: getUid("new_"),
				type: "SINGLE_CHOICE",
				value: "",
				isFree: false,
			},
		];
	};

	// #endregion

	// #region question option crud logic
	addPollQuestionOption = (args: { question: { id: string } }) => {
		const questions = deepCopy(this.poll.questions);
		const editedQuestionIndex = questions.findIndex((q) => q.id === args.question.id);
		const isFreeOptionExist = questions[editedQuestionIndex].answerOptions.some((option) => option.isFree);
		const newOption = {
			id: getUid("new_question_answer"),
			isFree: false,
			value: "",
		};

		if (isFreeOptionExist) {
			questions[editedQuestionIndex].answerOptions = [
				...questions[editedQuestionIndex].answerOptions.slice(0, -1),
				newOption,
				last(questions[editedQuestionIndex].answerOptions),
			];
		} else {
			questions[editedQuestionIndex].answerOptions.push({
				id: getUid("new_question_answer"),
				isFree: false,
				value: "",
			});
		}

		this.poll.questions = questions;
	};

	removeQuestionOption = (args: { question: { id: string }; option: { index: number } }) => {
		const questions = deepCopy(this.poll.questions);
		const editedQuestionIndex = questions.findIndex((q) => q.id === args.question.id);

		questions[editedQuestionIndex].answerOptions = [
			...questions[editedQuestionIndex].answerOptions.slice(0, args.option.index),
			...questions[editedQuestionIndex].answerOptions.slice(args.option.index + 1),
		];

		this.poll.questions = questions;
	};

	changeQuestionOption = (args: { question: { id: string }; option: { index: number; value: string } }) => {
		const questions = deepCopy(this.poll.questions);
		const editedQuestionIndex = questions.findIndex((q) => q.id === args.question.id);

		questions[editedQuestionIndex].answerOptions.splice(args.option.index, 1, {
			...questions[editedQuestionIndex].answerOptions[args.option.index],
			value: args.option.value,
		});

		this.poll.questions = questions;
	};

	toggleQuestionOptionFreeAnswer = (args: { question: { id: string } }) => {
		const questions = deepCopy(this.poll.questions);
		const editedQuestion = questions.find((q) => q.id === args.question.id);
		const isFreeAnswerExist = editedQuestion.answerOptions.some((q) => q.isFree);

		if (isFreeAnswerExist) {
			questions.splice(questions.length - 1, 1);
		} else {
			editedQuestion.answerOptions.push({
				id: getUid("new_question_free_answer"),
				isFree: true,
				value: null,
			});
		}

		this.poll.questions = questions;
	};

	// #endregion

	copyQuestion = (args: { question: { id: string } }) => {
		const questions = deepCopy(this.poll.questions);
		const copiedQuestion = questions.find((q) => q.id === args.question.id);
		questions.push({
			...copiedQuestion,
			id: getUid("new_question"),
		});

		this.poll.questions = questions;
	};

	// #region reordering logic
	reorderQuestions = (args: { endDragResult: DropResult }) => {
		const questions = deepCopy(this.poll.questions);

		const [removedPoll] = questions.splice(args.endDragResult.source.index, 1);
		questions.splice(args.endDragResult.destination.index, 0, removedPoll);

		this.poll.questions = questions;
	};

	reorderQuestionOptions = (args: { endDragResult: DropResult; question: { id: string } }) => {
		const questions = deepCopy(this.poll.questions);
		const editedQuestion = questions.find((q) => q.id === args.question.id);

		const [removedOption] = editedQuestion.answerOptions.splice(args.endDragResult.source.index, 1);
		editedQuestion.answerOptions.splice(args.endDragResult.destination.index, 0, removedOption);

		this.poll.questions = questions;
	};

	// #endregion

	dispose = () => {
		this.poll = initialPoll;
		this.isPoppulate = false;
		this.haslocationBeenChecked = false;
	};

	private getPollWithResettedIds = (poll: IVoteDto) => ({
		...this.poll,
		...{
			...poll,
			id: "",
			questions: poll.questions.map((question) => ({
				id: getUid("new_"),
				...question,
				answerOptions: question.answerOptions.map((answer) => ({
					id: getUid("new_"),
					...answer,
				})),
			})),
		},
	});
}

export default new PollsItemStore();
