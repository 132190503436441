import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import { FormItem, InputNumber, Select, TContextMenuItem, Text, TimePicker, colors, showToast } from "tap2visit-ui-kit";

import DatePicker from "components/DatePicker";
import { Divider } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import { TTimeLifeType } from "interfaces/IVote";

import PollsEditStore from "../store/PollsItem.store";
import { toJS } from "mobx";

interface IPollsPeriodOfTime {
	validationData: {
		isStartDateTimeValid: boolean;
		isEndDateTimeValid: boolean;
		isStartTimeValid: boolean;
		isEndTimeValid: boolean;
	};
	updateValidation: (field: keyof IPollsPeriodOfTime["validationData"], isValid: boolean) => void;
}

type TSelectRadioType = "periodOfTime" | "planned";

const PollsPeriodOfTime: FC<IPollsPeriodOfTime> = ({ validationData, updateValidation }) => {
	const [selectedRadio, setSelectedRadio] = React.useState<TSelectRadioType>(
		PollsEditStore.poll.startDateTime?.length ? "planned" : "periodOfTime",
	);
	const [isOpenSelectDayOfPeriod, setIsOpenSelectDayOfPeriod] = React.useState(false);
	const [listOfPeriodOfTime, setListOfPeriodOfTime] = React.useState<TContextMenuItem[]>([
		{
			focused: PollsEditStore.poll.timeLifeType === "DAY",
			text: "Сутки",
			key: "DAY" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "WEEK",
			text: "Неделя",
			key: "WEEK" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "MONTH",
			text: "Месяц",
			key: "MONTH" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "YEAR",
			text: "Год",
			key: "YEAR" as TTimeLifeType,
		},
	]);

	const onChangeSelectRadio = (type: TSelectRadioType) => {
		setSelectedRadio(type);
		if (type === "periodOfTime") {
			PollsEditStore.changeTimeLife({
				timeLifeType: "DAY",
				timeLifeValue: 1,
			});
		}

		if (type === "planned") {
			PollsEditStore.changeStartEndTimePoll({
				type: "startDateTime",
				typeOfTime: "day",
				value: dayjs(),
			});

			PollsEditStore.changeStartEndTimePoll({
				type: "endDateTime",
				typeOfTime: "day",
				value: dayjs().add(1, "day"),
			});
		}
	};

	const onClickSelectItem = (item: TContextMenuItem) => {
		setListOfPeriodOfTime((list) =>
			list.map((currItem) => {
				if (currItem.key === item.key) {
					PollsEditStore.changeTimeLife({
						timeLifeType: currItem.key as TTimeLifeType,
					});

					return {
						...currItem,
						focused: true,
					};
				}
				return {
					...currItem,
					focused: false,
				};
			}),
		);
	};

	const onChange = (value: number) => {
		PollsEditStore.changeTimeLife({
			timeLifeValue: value,
		});
	};

	const handleChangeStartDate = (selectedDate: dayjs.Dayjs) => {
		const oldStartDate = PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : null;

		if (oldStartDate && oldStartDate.isSame(selectedDate, "day")) {
			return;
		}

		const today = dayjs();
		const endDate = PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : null;

		PollsEditStore.changeStartEndTimePoll({
			type: "startDateTime",
			typeOfTime: "day",
			value: selectedDate,
		});

		if (selectedDate.isBefore(today.startOf("day"))) {
			showToast({ type: "danger", description: "Нельзя выбрать дату в прошлом" });
			updateValidation("isStartDateTimeValid", false);
			return;
		}

		if (endDate && selectedDate.isAfter(endDate)) {
			const endDateFormatted = endDate.format("DD.MM.YYYY");
			showToast({
				type: "danger",
				description: `Дата начала не может быть позже даты окончания ${endDateFormatted}`,
			});
			updateValidation("isStartDateTimeValid", false);
			return;
		}

		updateValidation("isStartDateTimeValid", true);
	};

	const onChangeStartTime = (value: dayjs.Dayjs) => {
		const now = dayjs();
		const oldStartTime = PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : null;

		if (oldStartTime && oldStartTime.hour() === value.hour() && oldStartTime.minute() === value.minute()) {
			return;
		}

		if (oldStartTime && oldStartTime.isSame(now, "day") && value.isBefore(now)) {
			showToast({
				type: "danger",
				description: "Нельзя выбрать время раньше текущего для сегодняшней даты",
			});
			updateValidation("isStartTimeValid", false);
			return;
		}

		updateValidation("isStartTimeValid", true);
		PollsEditStore.changeStartEndTimePoll({
			type: "startDateTime",
			typeOfTime: "time",
			value,
		});
	};

	const handleChangeEndDate = (selectedDate: dayjs.Dayjs) => {
		const oldEndDate = PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : null;

		if (oldEndDate && oldEndDate.isSame(selectedDate, "day")) {
			return;
		}

		const startDateTime = PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : null;

		PollsEditStore.changeStartEndTimePoll({
			type: "endDateTime",
			typeOfTime: "day",
			value: selectedDate,
		});
		if (startDateTime && selectedDate.isBefore(startDateTime, "day")) {
			const startDateFormatted = startDateTime.format("DD.MM.YYYY");
			showToast({
				type: "danger",
				description: `Дата окончания не может быть раньше даты начала ${startDateFormatted}`,
			});
			updateValidation("isEndDateTimeValid", false);
			return;
		}

		updateValidation("isEndDateTimeValid", true);
	};

	const onChangeEndTime = (value: dayjs.Dayjs) => {
		const startDateTime = PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : null;

		const oldEndTime = PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : null;

		const newEndTime = oldEndTime
			? oldEndTime.set("hour", value.hour()).set("minute", value.minute())
			: dayjs().set("hour", value.hour()).set("minute", value.minute());

		if (oldEndTime && oldEndTime.hour() === value.hour() && oldEndTime.minute() === value.minute()) {
			return;
		}

		if (startDateTime && newEndTime.isSame(startDateTime, "day") && value.isBefore(startDateTime)) {
			showToast({
				type: "danger",
				description: "Время окончания не может быть раньше времени начала",
			});
			updateValidation("isEndTimeValid", false);
			return;
		}

		updateValidation("isEndTimeValid", true);
		PollsEditStore.changeStartEndTimePoll({
			type: "endDateTime",
			typeOfTime: "time",
			value,
		});
	};

	return (
		<>
			<FormItem label="Время жизни опроса">
				<Container>
					<CustomText
						onClick={() => onChangeSelectRadio("periodOfTime")}
						selected={selectedRadio === "periodOfTime"}
						type="base-medium"
						style={{ flexGrow: 1 }}>
						В течение отрезка времени
					</CustomText>

					<Divider horizontal />

					<CustomText
						style={{ flexShrink: 1 }}
						onClick={() => onChangeSelectRadio("planned")}
						selected={selectedRadio === "planned"}
						type="base-medium">
						Запланировано
					</CustomText>
				</Container>
			</FormItem>
			<Spacer px={16} />
			{selectedRadio === "periodOfTime" && (
				<>
					<Row>
						{/* <InputNumber sizeInput={"medium"} value={Number(PollsEditStore.poll.timeLifeValue)} onChange={onChangeTimeLifeValue} /> */}
						<InputNumber sizeInput={"medium"} value={Number(PollsEditStore.poll.timeLifeValue)} onChangeValue={onChange} min={1} />
						<Select
							style={{ width: "140px" }}
							items={listOfPeriodOfTime}
							isOpened={isOpenSelectDayOfPeriod}
							onClickSelect={() => setIsOpenSelectDayOfPeriod(true)}
							onClickSelectItem={onClickSelectItem}
							onClose={() => setIsOpenSelectDayOfPeriod(false)}
							size="m"
						/>
					</Row>
				</>
			)}
			{selectedRadio === "planned" && (
				<>
					<CalendarRow>
						<FormItem label="Начало">
							<DatePicker
								disabledDates={{
									from: PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime).add(1, "day").toDate() : undefined,
								}}
								currentDate={PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : dayjs()}
								onChangeDate={(date) => handleChangeStartDate(dayjs(date[0]))}
								isError={!validationData.isStartDateTimeValid}
							/>
						</FormItem>
						<TimePicker
							initialTime={PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : dayjs()}
							onChange={onChangeStartTime}
							isError={!validationData.isStartTimeValid}
						/>
					</CalendarRow>

					<Spacer px={8} />

					<CalendarRow>
						<FormItem label="Окончание">
							<DatePicker
								disabledDates={{
									to: PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime).subtract(1, "day").toDate() : undefined,
								}}
								currentDate={PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : dayjs()}
								onChangeDate={(date) => handleChangeEndDate(dayjs(date[0]))}
								isError={!validationData.isEndDateTimeValid}
							/>
						</FormItem>
						<TimePicker
							initialTime={PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : dayjs()}
							onChange={onChangeEndTime}
							isError={!validationData.isEndTimeValid}
						/>
					</CalendarRow>
				</>
			)}
		</>
	);
};

export default observer(PollsPeriodOfTime);

const Container = styled.div`
	display: flex;
	border: 0.5px solid ${colors.strokeDivider};
	border-radius: 8px;
	width: 350px;
	cursor: pointer;
`;

const CustomText = styled(Text)<{ selected: boolean }>`
	padding: 5px 12px;
	${(props) => {
		if (props.selected) {
			return css`
				background: ${colors.surfaceBrandTransparent};
				color: ${colors.textBrandDefault};
			`;
		}
	}}
`;

const Row = styled.div`
	display: flex;
	gap: 16px;
`;

const CalendarRow = styled.div`
	display: flex;
	gap: 4px;
	align-items: end;
`;
