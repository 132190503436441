import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DownloadFile, DownloadFileIcon, Loader } from "tap2visit-ui-kit";

import { getListFilesChat } from "api/api.gateway";
import { downloadFileApi } from "api/api.storage";
import { IFileistItem } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";

interface IChatFileInformation {
	fileId: string;
	isFileDrawer?: boolean;
	isReplayMessage?: boolean;
	isCreatedByCurrentUser?: boolean;
	messageTime?: string;
}
const ChatFileInformation = ({ isCreatedByCurrentUser, messageTime, isReplayMessage, fileId, isFileDrawer }: IChatFileInformation) => {
	const [fileItem, setFileItem] = useState<IFileistItem>({} as IFileistItem);
	const { id } = useParams<{ id: string }>();
	const fileSize = `${String(Math.round(Number(fileItem?.size) / 1024))} КБ ${
		isFileDrawer ? `, ${dayjs(messageTime).format("DD MMM в hh:mm")}` : ""
	}`;
	const file = useQuery({
		queryFn: () => getListFilesChat({ params: { chatId: id } }),
		enabled: !!id,
		queryKey: [QueriesKeys.fileChatInformation, id],
		onSuccess: (data) => {
			setFileItem(data.content.find((v) => v.id === fileId));
		},
	});

	useEffect(() => {
		if (file.data) {
			setFileItem(file.data.content.find((v) => v.id === fileId));
		}
	}, [file]);

	const fileDownload = useQuery({
		queryFn: () => downloadFileApi(fileId),
		enabled: !!fileId,
		queryKey: [QueriesKeys.fileChatInformationDownload, fileId],
	});

	if (file.isLoading || !fileId || file.isFetching || fileDownload.isLoading || fileDownload.isFetching || !fileItem) return <Loader />;
	return (
		<ChatFileInformationWrapper isFileDrawer={isFileDrawer}>
			{isReplayMessage && isFileDrawer && <DownloadFileIcon fileName={fileItem.fileName} size={String(fileItem.size) as "m" | "s"} />}
			{!isReplayMessage && (
				<DownloadFile
					styleFileSizeText={{ color: !isFileDrawer && isCreatedByCurrentUser ? "white" : "#12121299" }}
					isSpaceBetweenText={!isFileDrawer}
					isNotDownlaodIcon={!isFileDrawer}
					styleNameText={{ color: !isFileDrawer && isCreatedByCurrentUser ? "white" : "#121212E6" }}
					style={{ height: "100%", background: !isFileDrawer ? "#00000017" : "transparent" }}
					name={fileItem.fileName || ""}
					fileSize={fileSize}
					size={isFileDrawer ? "m" : "s"}
					url={window.URL.createObjectURL(fileDownload?.data)}
				/>
			)}
		</ChatFileInformationWrapper>
	);
};

const ChatFileInformationWrapper = styled.div<{ isFileDrawer: boolean }>`
	display: flex;
	align-items: center;
	gap: 5px;
	margin: ${({ isFileDrawer }) => (isFileDrawer ? "10px 0px" : "0px")};
`;

export default ChatFileInformation;
