import React from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { colors, Text } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.employee";
import { getListChat } from "api/api.gateway";
import { IMessageContent } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";

import { RepliedMessageBox, TextStyledMessage } from "./Chat.styled";
import ChatFileInformation from "./ChatFileInformation";

const ChatRepliedMessage = ({
	content,
	isCreatedByCurrentUser,
	refs,
}: {
	content: IMessageContent;
	isCreatedByCurrentUser?: boolean;
	refs: any;
}) => {
	const { id } = useParams<{ id: string }>();

	const { data: user } = useQuery({
		queryFn: () => getListChat({ params: {} }),
		queryKey: [QueriesKeys.chatList],
	});

	const { data: employee } = useQuery({
		queryFn: () => getUserByIdApi({ params: { id: content.repliedMessage.participant.authorId } }),
		queryKey: [QueriesKeys.chatList, content.repliedMessage.participant.authorId, content.repliedMessage.participant.type],
		enabled: content.repliedMessage.participant.type !== "CLIENT",
	});

	const name = user?.content.find((userItem) => userItem.id === id).name;
	const fullName = `${employee?.lastName} ${employee?.firstName} ${employee?.middleName}`;
	const isHaveFileIds = content.repliedMessage.fileIds.length > 0;

	return (
		<RepliedMessageBox
			onClick={() =>
				refs[content.repliedMessage.id].current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}>
			<Text
				style={{
					marginBottom: isHaveFileIds ? "10px" : "",
				}}
				type="small-medium"
				color={isCreatedByCurrentUser ? colors.surfaceNeutralBgWhite : colors.textBrandDefault}>
				{content.repliedMessage.participant.type === "CLIENT" ? name : fullName}
			</Text>
			{isHaveFileIds && content.repliedMessage.fileIds.map((file) => <ChatFileInformation fileId={file} />)}
			<TextStyledMessage type="small-regular" color={isCreatedByCurrentUser ? colors.surfaceNeutralBgWhite : colors.textNeutralSecondary}>
				{content.repliedMessage.text}
			</TextStyledMessage>
		</RepliedMessageBox>
	);
};

export default ChatRepliedMessage;
