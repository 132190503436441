import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Chip, FormItem, showToast } from "tap2visit-ui-kit";

import { TNotificationType } from "interfaces/IVote";

import PollsEditStore from "../../../store/PollsItem.store";

interface IPollSendModalContentProps {
	validationData: {
		isMethodValid: boolean;
	};
	updateValidation: (field: keyof IPollSendModalContentProps["validationData"], isValid: boolean) => void;
}

const PollSendModalContent: FC<IPollSendModalContentProps> = ({ validationData, updateValidation }) => {
	const initialNotificationTypes = new Set(PollsEditStore.poll.notificationTypes);

	const [items, setItems] = React.useState([
		{
			selected: initialNotificationTypes.has("PUSH"),
			key: "PUSH" as TNotificationType,
			text: "Моб. приложение",
		},
		// {
		// 	selected: initialNotificationTypes.has("EMAIL"),
		// 	key: "EMAIL" as TNotificationType,
		// 	text: "Эл. почта",
		// },
		// {
		// 	selected: initialNotificationTypes.has("SMS"),
		// 	key: "SMS" as TNotificationType,
		// 	text: "SMS",
		// },
	]);

	const onChipClick = (key: TNotificationType) => {
		PollsEditStore.changeNotificationTypes({ notificationType: key });

		const hasMethod = PollsEditStore.poll.notificationTypes.length > 0;

		setItems((methods) => methods.map((method) => (method.key === key ? { ...method, selected: !method.selected } : method)));

		if (!hasMethod) {
			showToast({ type: "danger", description: "Выберите хотя бы один метод отправки" });
			updateValidation("isMethodValid", false);
			return false;
		}

		updateValidation("isMethodValid", true);
		return true;
	};

	return (
		<FormItem label="Отправить с помощью">
			<>
				<Row>
					{items.map((item) => (
						<Chip
							isError={!validationData.isMethodValid}
							key={item.key}
							onClick={() => onChipClick(item.key)}
							title={item.text}
							selected={item.selected}
							size={"m"}
						/>
					))}
				</Row>
				{!validationData.isMethodValid && <ErrorText>Выберите метод отправки</ErrorText>}
			</>
		</FormItem>
	);
};

export default observer(PollSendModalContent);

const Row = styled.div`
	display: flex;
	gap: 4px;
`;

const ErrorText = styled.div`
	color: red;
	font-size: 12px;
	margin-top: 8px;
`;
