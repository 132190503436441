import React from "react";

import { useQuery } from "react-query";
import { TContextMenuItem } from "tap2visit-ui-kit";

import { getPollByIdApi, getVotesTempatesPageApi } from "api/api.votes";
import { TVoteType } from "interfaces/IVote";
import { QueriesKeys } from "interfaces/queriesKeys";
import PollsEditStore from "pages/polls/store/PollsItem.store";
import PollsItemStore from "pages/polls/store/PollsItem.store";

const usePollTemplatesSelectItems = () => {
	const [isOpenSelect, setIsOpenSelect] = React.useState(false);

	const [pollTemplatesContextItems, setPollTemplatesContextItems] = React.useState<TContextMenuItem[]>([]);
	const selectedTemplateContextItem = pollTemplatesContextItems.find((poll) => poll.focused);

	const selectedTemplate = useQuery({
		queryFn: () =>
			getPollByIdApi({
				path: {
					id: selectedTemplateContextItem.key,
				},
			}),
		queryKey: [QueriesKeys.poll, selectedTemplateContextItem?.key],
		enabled: !!selectedTemplateContextItem?.key,
	});

	const pollsTemplates = useQuery({
		queryFn: () => {
			const types: TVoteType[] = ["POLL", "VOTE"];
			return getVotesTempatesPageApi({
				params: {
					page: 0,
					size: 100,
					sort: [{ key: "createdAt", direction: "desc" }],

					types: types,
				},
			});
		},
		queryKey: [QueriesKeys.pollTemplates],
	});

	const onChangeTemplate = (item: TContextMenuItem) => {
		setPollTemplatesContextItems((currItems) =>
			currItems.map((currItem) => {
				if (item.key === currItem.key) {
					return {
						...currItem,
						focused: !currItem.focused,
					};
				}
				return {
					...currItem,
					focused: false,
				};
			}),
		);
		setIsOpenSelect(false);
	};

	const onClickSelect = () => setIsOpenSelect((status) => !status);

	React.useEffect(() => {
		setPollTemplatesContextItems(
			pollsTemplates.data?.content?.map((poll) => ({
				focused: false,
				key: poll.id,
				text: poll.name,
			})) ?? [],
		);
	}, [pollsTemplates.data]);

	React.useEffect(() => {
		if (selectedTemplate.data) {
			PollsEditStore.populatePoll({
				poll: {
					...selectedTemplate.data,
					isTemplate: false,
				},
				mode: "new",
			});
		}
	}, [selectedTemplate.data]);

	React.useEffect(() => {
		PollsItemStore.setIsLoadingTemplate(selectedTemplate.isLoading);
	}, [selectedTemplate.isLoading]);

	return { items: pollTemplatesContextItems, onChangeTemplate, onClickSelect, isOpenSelect };
};

export default usePollTemplatesSelectItems;
