import { TPollsTabs } from "pages/polls/Polls/store/Polls.tabs.store";

export const getHeaderTitle = (activeTab: TPollsTabs): string => {
	if (activeTab === "template") {
		return "Шаблоны";
	} else if (activeTab === "active") {
		return "Действующие опросы и голосования";
	}
	return "Архив опросов и голосований";
};
