import React, { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, showToast } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import { ReactComponent as ArrowLeftSvg } from "public/arrow_left.svg";
import { ROUTES } from "routes/constants/routes.constants";
import { DEFAULT_PAGE_HEADER_PADDING } from "routes/withSideBar";

import useSavePoll from "../hooks/useSavePoll";
import PollsEditStore from "../store/PollsItem.store";
import PollsItemStore from "../store/PollsItem.store";
import PollsEditTabsStore, { TPollsEditTabsVariants } from "../store/PollsItem.tabs.store";
import PollsSendModalStore from "../store/PollsSendModal.store";
import PollsEditValidationStore from "../store/PollsEdit.validation.store";
import { validation } from "modules/validation/utils/validation";

interface IPollsHeader {}

const tabs: { key: TPollsEditTabsVariants; label: string }[] = [
	{
		key: "questions",
		label: "Вопросы",
	},
	{
		key: "answers",
		label: "Ответы",
	},
];

const PollsHeader: FC<IPollsHeader> = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const isTemplate = PollsItemStore.poll?.isTemplate;
	const savePoll = useSavePoll();

	React.useEffect(() => {
		const topHeaderContainer = document.getElementById("top-page-header");
		if (topHeaderContainer) {
			topHeaderContainer.style.padding = "14px 16px 0 16px";
		}

		if (location.pathname.startsWith(`/${ROUTES.VOTE}/${ROUTES.CREATE_TEMPLATE}`)) {
			PollsItemStore.poll.isTemplate = true;
		} else {
			PollsItemStore.poll.isTemplate = false;
		}

		return () => {
			if (topHeaderContainer) {
				topHeaderContainer.style.padding = DEFAULT_PAGE_HEADER_PADDING;
			}
		};
	}, [location.pathname]);

	const validatePoll = (): boolean => {
		const { name, questions, type, isTemplate } = PollsEditStore.poll;
		const questionValues = new Set<string>();

		PollsEditValidationStore.dispose();

		const isValid = PollsEditValidationStore.checkPollValidation();

		if (!isValid) {
			questions.forEach((question) => {
				if (!PollsEditValidationStore.isQuestionTouched(question.id)) {
					PollsEditValidationStore.touchQuestion(question.id);
				}

				question.answerOptions.forEach((option) => {
					if (!PollsEditValidationStore.isOptionTouched(option.id)) {
						PollsEditValidationStore.touchOption(option.id);
					}
				});
			});

			return false;
		}

		if (isTemplate) {
			if (!name || name.trim() === "" || !validation.isLessThen(name, 255) || !PollsEditValidationStore.isValidPollName) {
				showToast({
					description: `Название шаблона не может быть пустым или быть более 255 символов `,
					type: "danger",
				});
				return false;
			}
		}

		if (!name || name.trim() === "" || !validation.isLessThen(name, 255) || !PollsEditValidationStore.isValidPollName) {
			showToast({
				description: `Название ${type === "POLL" ? "опроса" : "голосования"} не может быть пустым или быть более 255 символов`,
				type: "danger",
			});
			return false;
		}

		for (const question of questions) {
			const trimmedQuestionValue = question.value?.trim();

			if (!question.value || !validation.isLessThen(question.value, 255) || question.value?.trim() === "") {
				showToast({
					description: "Текст вопроса не может быть пустым или быть более 255 символов",
					type: "danger",
				});
				return false;
			}

			if (questionValues.has(trimmedQuestionValue)) {
				showToast({
					description: "Вопросы не должны повторяться",
					type: "danger",
				});
				return false;
			}
			questionValues.add(trimmedQuestionValue);

			PollsEditValidationStore.checkDuplicateOptions(question.answerOptions);

			for (const option of question.answerOptions) {
				if (option.isFree) {
					continue;
				}

				if (!option.value || !validation.isLessThen(option.value, 255) || option.value?.trim() === "") {
					showToast({
						description: "Текст ответа не может быть пустым или быть более 255 символов",
						type: "danger",
					});
					return false;
				}

				if (PollsEditValidationStore.duplicateOptionIds.includes(option.id)) {
					showToast({
						description: "Варианты ответов не могут повторяться",
						type: "danger",
					});
					return false;
				}
			}
		}

		return isValid;
	};

	const onSend = (isTemplate?: boolean) => {
		PollsEditValidationStore.touchAllQuestions();

		if (!validatePoll()) {
			return;
		}

		PollsSendModalStore.changeStatusModal(true, isTemplate);
	};

	const isVisibleSaveButtons = location.pathname.startsWith("/polls/edit") || location.pathname.startsWith("/polls/create");
	const patchTemplate = () => {
		savePoll({
			realEstateIds: {
				complexIds: PollsItemStore.poll.complexIds,
				buildingIds: PollsItemStore.poll.buildingIds,
				entranceIds: PollsItemStore.poll.entranceIds,
				floorIds: PollsItemStore.poll.floorIds,
				buildingObjectIds: PollsItemStore.poll.buildingObjectIds,
			},
		});
	};

	useEffect(
		() => () => {
			PollsEditStore.dispose();
			PollsEditTabsStore.setActiveTab("questions");
		},
		[],
	);

	return (
		<Container>
			<HeaderTopContainer>
				<BackLink onClick={() => navigate("/polls", { replace: true })}>
					<Button shape="square" size="medium" icon={ArrowLeftSvg} typeButton="text" />
					<HeaderTextContainer>
						<HeaderText>{PollsEditStore.poll?.name}</HeaderText>
					</HeaderTextContainer>
				</BackLink>

				{isVisibleSaveButtons && !isTemplate && (
					<GroupButtons>
						<Button onClick={() => onSend(true)} typeButton="secondary">
							Сохранить как шаблон
						</Button>
						<Button onClick={() => onSend()}>Отправить</Button>
					</GroupButtons>
				)}

				{isVisibleSaveButtons && isTemplate && (
					<GroupButtons>
						<Button onClick={() => onSend(true)}>Сохранить шаблон</Button>
					</GroupButtons>
				)}
			</HeaderTopContainer>

			<Spacer px={16} />
		</Container>
	);
};

export default observer(PollsHeader);

const HeaderTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const GroupButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	flex-shrink: 0;
`;

const BackLink = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	min-width: 0;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const HeaderTextContainer = styled.div`
	flex-grow: 1;
	min-width: 0;
`;

const HeaderText = styled.h4`
	white-space: normal;
	word-wrap: break-word;
	text-overflow: ellipsis;
`;
