import { FC } from "react";

import { observer } from "mobx-react-lite";

import Tab from "components/ui/Tab/Tab";

import PollsEditStore from "../../../../../src/pages/polls/store/PollsItem.store";
import PollsTabsStore, { TPollsTabs } from "../store/Polls.tabs.store";

interface IPollsTabs {}

const tabs: { key: TPollsTabs; label: string }[] = [
	{
		key: "active",
		label: "Действующие",
	},
	{
		key: "archive",
		label: "Проведенные",
	},
	{
		key: "template",
		label: "Шаблоны",
	},
];

const handleTabClick = (key: TPollsTabs) => {
	PollsTabsStore.setActiveTab(key);
};

const PollsTabs: FC<IPollsTabs> = (props) => (
	<Tab
		style={{ width: "100%" }}
		activeKey={PollsTabsStore.activeTab}
		items={tabs}
		paddingItem={"12px"}
		onTabClick={(key: TPollsTabs) => handleTabClick(key)}
	/>
);

export default observer(PollsTabs);
