import { makeAutoObservable } from "mobx";
import { validation } from "modules/validation/utils/validation";

import PollsEditStore from "./PollsItem.store";

class PollsEditValidationStore {
	isValidPollName = true;
	notValidQuestionNameIds: string[] = [];
	notValidQuestionOptions: string[] = [];
	duplicateOptionIds: string[] = [];
	touchedQuestionIds: Set<string> = new Set();
	touchedOptionIds: Set<string> = new Set();
	questionErrors: Record<string, string | null> = {};

	constructor() {
		makeAutoObservable(this);
	}

	checkPollValidation = () => {
		this.dispose();

		const poll = PollsEditStore.poll;

		this.isValidPollName = validation.isNotEmpty(poll.name) && validation.isLessThen(poll.name, 255) && poll.name.trim() !== "";

		const questionValues = new Set<string>();

		poll.questions.forEach((question) => {
			const trimmedQuestionValue = question.value?.trim();
			this.validateQuestion(question.id, question.value);
			this.checkDuplicateOptions(question.answerOptions);

			if (!validation.isNotEmpty(trimmedQuestionValue) || !validation.isLessThen(trimmedQuestionValue, 255)) {
				if (!this.notValidQuestionNameIds.includes(question.id)) {
					this.notValidQuestionNameIds.push(question.id);
				}
			} else {
				if (questionValues.has(trimmedQuestionValue)) {
					if (!this.notValidQuestionNameIds.includes(question.id)) {
						this.notValidQuestionNameIds.push(question.id);
					}
				} else {
					questionValues.add(trimmedQuestionValue);
					this.notValidQuestionNameIds = this.notValidQuestionNameIds.filter((id) => id !== question.id);
				}
			}

			this.checkDuplicateOptions(question.answerOptions);

			question.answerOptions.forEach((option) => {
				if (option.isFree) {
					return;
				}

				const trimmedValue = option.value?.trim();

				if (
					!validation.isNotEmpty(trimmedValue) ||
					!validation.isLessThen(trimmedValue, 255) ||
					this.duplicateOptionIds.includes(option.id)
				) {
					if (!this.notValidQuestionOptions.includes(option.id)) {
						this.notValidQuestionOptions.push(option.id);
					}
				} else {
					this.notValidQuestionOptions = this.notValidQuestionOptions.filter((id) => id !== option.id);
				}
			});
		});

		return (
			this.isValidPollName &&
			this.notValidQuestionNameIds.length === 0 &&
			this.notValidQuestionOptions.length === 0 &&
			this.duplicateOptionIds.length === 0
		);
	};

	validPollName = () => {
		this.isValidPollName = true;
	};

	validQuestionName = (id: string) => {
		this.notValidQuestionNameIds = this.notValidQuestionNameIds.filter((questionId) => questionId !== id);
		this.touchedQuestionIds.add(id);
	};

	validQuestionOption = (id: string) => {
		this.notValidQuestionOptions = this.notValidQuestionOptions.filter((optionId) => optionId !== id);
		this.touchedOptionIds.add(id);
	};

	checkDuplicateOptions = (answerOptions) => {
		this.duplicateOptionIds = [];
		const seenOptions = new Map();

		answerOptions.forEach((option) => {
			if (option.isFree) return;

			const trimmedValue = option.value?.trim();

			if (seenOptions.has(trimmedValue)) {
				const duplicateId = seenOptions.get(trimmedValue);

				if (!this.duplicateOptionIds.includes(option.id)) {
					this.duplicateOptionIds.push(option.id);
				}
				if (!this.duplicateOptionIds.includes(duplicateId)) {
					this.duplicateOptionIds.push(duplicateId);
				}
			} else {
				seenOptions.set(trimmedValue, option.id);
			}
		});
	};

	validateQuestion = (questionId: string, questionValue: string) => {
		const poll = PollsEditStore.poll;

		const trimmedValue = questionValue.trim();
		const isEmpty = !validation.isNotEmpty(trimmedValue);
		const isTooLong = !validation.isLessThen(trimmedValue, 255);
		const isDuplicate = poll.questions.some((question) => question.id !== questionId && question.value?.trim() === trimmedValue);

		if (isEmpty || isTooLong) {
			this.questionErrors[questionId] = "Вопрос не может быть пустым или быть более 255 символов";
		} else if (isDuplicate) {
			this.questionErrors[questionId] = "Вопросы не должны повторяться";
		} else {
			this.questionErrors[questionId] = null;
		}
	};

	getQuestionError = (questionId: string): string | null => this.questionErrors[questionId] || null;

	touchQuestion = (id: string) => {
		this.touchedQuestionIds.add(id);
	};

	touchOption = (id: string) => {
		this.touchedOptionIds.add(id);
	};

	isQuestionTouched = (id: string): boolean => this.touchedQuestionIds.has(id);

	isOptionTouched = (id: string): boolean => this.touchedOptionIds.has(id);

	touchAllQuestions = () => {
		PollsEditStore.poll.questions.forEach((question) => {
			this.touchQuestion(question.id);
			question.answerOptions.forEach((option) => {
				this.touchOption(option.id);
			});
		});
	};

	dispose = () => {
		this.isValidPollName = true;
		this.notValidQuestionNameIds = [];
		this.notValidQuestionOptions = [];
		this.duplicateOptionIds = [];
		this.touchedQuestionIds.clear();
		this.touchedOptionIds.clear();
		this.questionErrors = {};
	};
}

export default new PollsEditValidationStore();
