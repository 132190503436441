import React from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Space } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.employee";
import { getListChat } from "api/api.gateway";
import { IMessageContent } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";

import AvatarChat from "./AvatarChat";
import { ChatDropDownWrapper, TimeWrapper } from "./Chat.styled";

interface IChatDropDown {
	setOpenChatDropDown: (v: boolean) => void;
	searchItem: IMessageContent;
	handleClick: (id: string) => void;
	openChatDropDown: boolean;
}
const ChatDropDown = ({ searchItem, handleClick, setOpenChatDropDown }: IChatDropDown) => {
	const { id } = useParams<{ id: string }>();

	const { data: user } = useQuery({
		queryFn: () => getListChat({ params: {} }),
		queryKey: [QueriesKeys.chatList],
	});

	const { data: employee } = useQuery({
		queryFn: () => getUserByIdApi({ params: { id: searchItem.participant.authorId } }),
		queryKey: [QueriesKeys.chatList, searchItem.participant.authorId, searchItem.type],
		enabled: searchItem.type !== "CLIENT",
	});

	const firstName =
		searchItem.type === "CLIENT" ? user?.content.find((userItem) => userItem.id === id).name.split("")[0] : employee?.firstName;
	const lastName =
		searchItem.type === "CLIENT" ? user?.content.find((userItem) => userItem.id === id).name.split("")[1] : employee?.lastName;

	const onClickSearch = () => {
		setOpenChatDropDown(false);
		handleClick(searchItem.id);
	};
	return (
		<ChatDropDownWrapper onClick={onClickSearch} key={searchItem.id}>
			<Space justify="space-between" width="100%">
				<Space>
					<AvatarChat
						avatarId={searchItem.type === "CLIENT" ? user?.content.find((contentItem) => contentItem.id === id)?.avatarId : undefined}
						firstName={firstName}
						lastName={lastName}
					/>
					<Space size={5} direction="column" align="start">
						<div>
							{searchItem.type === "CLIENT"
								? user?.content.find((contentItem) => contentItem.id === id).name
								: `${employee?.lastName} ${employee?.firstName}`}
						</div>
						<div>{searchItem.text}</div>
					</Space>
				</Space>

				<TimeWrapper>{dayjs(searchItem.time).format("DD.MM.YYYY")}</TimeWrapper>
			</Space>
		</ChatDropDownWrapper>
	);
};

export default ChatDropDown;
